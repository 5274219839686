import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Button from '../button/Button'
import { returnCreateJobContext } from '../Contexts/CreateJobContext'
import BlurScreen from '../Templates/BlurScreen/BlurScreen'
import Input from '../Templates/FormsElement/Input'
import SearchInput from '../Templates/SearchInput/SearchInput'
import RenderForm from '../Templates/RenderForm/RenderForm'
import './existingForms.scss'

const ExistingForms = ({ closeExistingForms }) => {
  const navigate = useNavigate()
  const { existingJobForms, setSelectedSectionsIds, selectedSectionsIds } = returnCreateJobContext()
  const [searchFilter, setSearchFilter] = useState([])
  const [initialList, setInitialList] = useState([])
  const [intermittentSections, setIntermittentSections] = useState([...selectedSectionsIds])

  const [showSectionPreview, setShowSectionPreview] = useState(false)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)

  useEffect(() => {
    setSearchFilter([...existingJobForms])
    setInitialList([...existingJobForms])
  }, [existingJobForms])

  const renderFullForm = (index) => {
    setSelectedSectionIndex(index)
    setShowSectionPreview(true)
  }

  const saveSelection = () => {
    setSelectedSectionsIds(intermittentSections)

    if (closeExistingForms) {
      closeExistingForms()
    } else {
      navigate('/admin/newJob')
    }
  }

  return (
    <>
      <div className="existing-forms-wrapper">
        <SearchInput
          attributeToFilter={['title']}
          inputClass="full-container"
          initialList={initialList}
          listToFilter={searchFilter}
          setFilter={setSearchFilter}
        />
        <div className="form-header-wrapper">
          {
          searchFilter.map((item, index) => (
            <div className="form-section" key={`section-${item.id}`}>
              <div className="left-side-container">
                <Input
                  type="checkbox"
                  setChanges={setIntermittentSections}
                  validateChecked={intermittentSections}
                  checkedItems={selectedSectionsIds}
                  initialItemList={[{
                    id: item.id,
                    value: item.title,
                  }]}
                  inputName="selectedSectionsIds"
                />
              </div>
              <Button
                classButton="eye-container"
                onClick={() => renderFullForm(index)}
              />
            </div>
          ))
        }
        </div>
        <div className="form-buttons">
          <Button
            text="Cancel"
            classButton="cancel-button"
            onClick={() => closeExistingForms() || (() => navigate('/admin/newJob'))}
          />
          <Button
            text="Use"
            classButton="accept-button"
            onClick={saveSelection}
          />
        </div>
      </div>
      {
      showSectionPreview
        ? (
          <BlurScreen>
            <RenderForm
              sections={[existingJobForms[selectedSectionIndex]]}
              closeFunction={() => setShowSectionPreview(false)}
            />
          </BlurScreen>
        )
        : ''
      }
    </>
  )
}

ExistingForms.propTypes = {
  closeExistingForms: PropTypes.func,
}

ExistingForms.defaultProps = {
  closeExistingForms: undefined,
}

export default ExistingForms
