import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loggedUser, SET_BLUR_SCREEN } from '../../../UserContext/UserReducer'
import userIcon from '../../../../assets/images/settingsProfileOrange.svg'
import Input from '../FormsElement/Input'
import Button from '../../button/Button'
import closeIcon from '../../../../assets/images/close.svg'
import './userCard.scss'

const UserCard = () => {
  const dispatch = useDispatch()
  const userState = useSelector(loggedUser)
  const generalInformation = [{
    telephone: 12345678,
    name: userState.name,
    mail: userState.name,
    role: userState.userGroup,
  }]

  const handleFormClose = () => {
    dispatch(SET_BLUR_SCREEN({
      showBlurScreen: undefined,
    }))
  }
  return (
    <div className="user-card-wrapper">
      <Button classButton="close-icon" icon={[closeIcon]} onClick={() => handleFormClose()} />
      <Button text={userState?.name} icon={[userIcon]} classButton="user-profile" onClick={() => {}} />
      <div className="general-information-container">
        <Input
          initialItemList={generalInformation}
          label="Telephone"
          inputName="telephone"
        />
        <Input
          initialItemList={generalInformation}
          label="Mail"
          inputName="mail"
        />
        <Input
          initialItemList={generalInformation}
          label="Role"
          inputName="role"
        />
      </div>
    </div>
  )
}

export default UserCard
