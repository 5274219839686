var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Get candidates list
 */
export var fetchCandidates = function (token, query) {
    if (query === void 0) { query = ''; }
    return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/jobs/processes?").concat(query), {
                            method: 'GET',
                            headers: {
                                'Authorization': "Bearer ".concat(token)
                            },
                        }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _a = [res.status];
                                    return [4 /*yield*/, res.json()];
                                case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                            }
                        }); }); })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    err_1 = _a.sent();
                    return [2 /*return*/, ('Something went wrong. Error: ' + err_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
};
/**
 * Create new candidate
 */
export var createCandidate = function (candidateData, token) { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants"), {
                        method: 'POST',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(candidateData),
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: 
            // WE MAY RETURN ENTIRE RESPONSE O JUST THE STATUS CODE 200 (OK), BUT WE NEED AUTH TOKEN
            return [2 /*return*/, _a.sent()];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Get all candidates
 */
export var getCandidates = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, status_1, res, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants"), {
                        method: 'GET',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1:
                _a = _b.sent(), status_1 = _a[0], res = _a[1];
                return [2 /*return*/, [status_1, res]];
            case 2:
                error_2 = _b.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * updates a candidate by id
 */
export var updateCandidate = function (candidateData, token) { return __awaiter(void 0, void 0, void 0, function () {
    var error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/").concat(candidateData.id), {
                        method: 'PUT',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify({
                            statusId: candidateData.statusId,
                            recruiterId: candidateData.recruiterId,
                            name: candidateData.name,
                            lastName: candidateData.lastName,
                            email: candidateData.email,
                            cvPath: candidateData.cvPath,
                            englishLevel: candidateData.englishLevel,
                            rate: candidateData.rate,
                            technicalScore: candidateData.technicalScore,
                            statusPaymentRecruiterId: candidateData.statusPaymentRecruiterId,
                            comments: candidateData.comments,
                            createdBy: candidateData.createdBy,
                        }),
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: 
            // WE MAY RETURN ENTIRE RESPONSE O JUST THE STATUS CODE 200 (OK), BUT WE NEED AUTH TOKEN
            return [2 /*return*/, _a.sent()];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + error_3)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Disable candidate by ID
 */
export var disableCandidate = function (token, id) { return __awaiter(void 0, void 0, void 0, function () {
    var err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/").concat(id), {
                        method: 'DELETE',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                err_2 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + err_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Enable candidate by ID
 */
export var enableCandidate = function (token, id) { return __awaiter(void 0, void 0, void 0, function () {
    var err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/").concat(id), {
                        method: 'POST',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                err_3 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + err_3)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Assign candidate to JO
 */
export var assignCandidateToJO = function (token, model) { return __awaiter(void 0, void 0, void 0, function () {
    var err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/jobs/processes"), {
                        method: 'POST',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(model),
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                err_4 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + err_4)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Change candidate of status column
 */
export var updateCandidateProcess = function (token, model) { return __awaiter(void 0, void 0, void 0, function () {
    var err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/applicants/jobs/processes"), {
                        method: 'PUT',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                        body: JSON.stringify(model),
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                err_5 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + err_5)];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Get candidate model
 */
export var getCandidateModel = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(process.env.API_URL, "/forms/sections/1"), {
                        method: 'GET',
                        headers: {
                            'Authorization': "Bearer ".concat(token)
                        },
                    }).then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                err_6 = _a.sent();
                return [2 /*return*/, ('Something went wrong. Error: ' + err_6)];
            case 3: return [2 /*return*/];
        }
    });
}); };
