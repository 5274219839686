import React from 'react'
import PropTypes from 'prop-types'
import FormTitle from '../FormsTitle/FormTitle'
import closeIcon from '../../../../assets/images/close.svg'
import Button from '../../button/Button'
import { returnCreateJobContext } from '../../Contexts/CreateJobContext'
import './createfield.scss'

import addButton from '../../../../assets/images/createField/addField.svg'
import checkboxField from '../../../../assets/images/createField/checkboxField.svg'
import clockField from '../../../../assets/images/createField/clockField.svg'
import dateField from '../../../../assets/images/createField/dateField.svg'
import dropField from '../../../../assets/images/createField/dropField.svg'
import numField from '../../../../assets/images/createField/numField.svg'
import qualField from '../../../../assets/images/createField/qualField.svg'
import radioField from '../../../../assets/images/createField/radioField.svg'
import textField from '../../../../assets/images/createField/textField.svg'
import urlField from '../../../../assets/images/createField/urlField.svg'

const questionTypes = [
  {
    id: 'type-1',
    type: 'short',
    name: 'Short answer',
    image: textField,
  },
  {
    id: 'type-2',
    type: 'schedule',
    name: 'Schedule',
    image: clockField,
  },
  {
    id: 'type-3',
    type: 'long',
    name: 'Long answer',
    image: textField,
  },
  {
    id: 'type-4',
    type: 'qualification',
    name: 'Qualification',
    image: qualField,
  },
  {
    id: 'type-5',
    type: 'number',
    name: 'Number',
    image: numField,
  },
  {
    id: 'type-6',
    type: 'url',
    name: 'URL',
    image: urlField,
  },
  {
    id: 'type-7',
    type: 'selector',
    name: 'Dropdown',
    image: dropField,
  },
  {
    id: 'type-8',
    type: 'checkbox',
    name: 'Checkbox',
    image: checkboxField,
  },
  {
    id: 'type-9',
    type: 'radio',
    name: 'Radio button',
    image: radioField,
  },
  {
    id: 'type-10',
    type: 'date',
    name: 'Date',
    image: dateField,
  },
  {
    id: 'type-11',
    type: 'textbox',
    name: 'Textbox',
    image: textField,
  },
]

const AddNewField = ({ onClose, selectedSection }) => {
  const { editSection } = returnCreateJobContext()

  const addNewQuestion = (question) => {
    editSection(selectedSection, question)
    onClose()
  }

  return (
    <div className="form-wrapper create-job-opening">
      <div className="form-header-wrapper">
        <div className="form-header-wrapper-icons">
          <FormTitle title="Add new field" />
          <Button icon={[closeIcon]} onClick={() => onClose()} />
        </div>
        <div className="form-body-row">
          {
            questionTypes.map((item) => (
              <div className="create-field" key={item.id}>
                <div className="field-description">
                  <img src={item.image} alt="text-field" />
                  <span>{item.name}</span>
                </div>
                <Button
                  icon={[addButton]}
                  onClick={() => addNewQuestion(item)}
                />
              </div>

            ))
          }
        </div>
      </div>
    </div>
  )
}

AddNewField.propTypes = {
  onClose: PropTypes.func,
  selectedSection: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
}

AddNewField.defaultProps = {
  onClose: () => {},
}

export default AddNewField
