/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FormTitle from '../Templates/FormsTitle/FormTitle'
import closeIcon from '../../../assets/images/close.svg'
import Button from '../button/Button'
import '../Recruiter/CandidateForm/candidateForm.scss'
import './readMore.scss'
import Input from '../Templates/FormsElement/Input'
import Textbox from '../Templates/FormsElement/Textbox'
import { formatDate } from '../../utils/DateUtils'
import { returnCandidateContext } from '../Contexts/CandidatesContext'

const ReadMoreForm = ({ onClose }) => {
  const {
    selectedJob,
  } = returnCandidateContext()

  useEffect(() => {
    if (selectedJob) {
      selectedJob.rateMin = `$${selectedJob?.rateMin}/hour`
      selectedJob.rateMax = `$${selectedJob?.rateMax}/hour`
      selectedJob.limitDate = formatDate(selectedJob?.limitDate)
    }
  }, [])

  return (
    <div className="form-wrapper read-more">
      <div className="form-header-wrapper">
        {
          selectedJob
            ? (
              <>
                <div className="form-header-wrapper-icons">
                  <FormTitle title={selectedJob.form.name} />
                  <Button icon={[closeIcon]} onClick={() => onClose()} />
                </div>
                <div className="form-body-row">
                  <Input
                    initialItemList={[selectedJob]}
                    label="Client"
                    inputName="companyName"
                  />

                  <Input
                    initialItemList={[selectedJob]}
                    label="Min Rate"
                    inputName="rateMin"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Rol"
                    inputName="name"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Max Rate"
                    inputName="rateMax"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Job URL"
                    inputName="jobDescriptorPath"
                    className="full-container"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Country"
                    inputName="countryName"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="English required"
                    inputName="englishRequired"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Available until"
                    inputName="limitDate"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Creator"
                    inputName="createdBy"
                  />
                  <Input
                    initialItemList={[selectedJob]}
                    label="Expected region"
                    inputName="expectedRegionProfile"
                  />
                  <Textbox
                    initialItemList={[selectedJob]}
                    label="Description"
                    textboxName="description"
                    rows="3"
                  />
                </div>
              </>
            ) : ''
        }
      </div>
    </div>
  )
}

ReadMoreForm.propTypes = {
  onClose: PropTypes.func,
}

ReadMoreForm.defaultProps = {
  onClose: () => {},
}

export default ReadMoreForm
