import React, { useState, useEffect } from 'react'
import {
  Routes, Route, Navigate,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Login from './components/login/Login'
import ResetPassword from './components/ResetPassword/ResetPassword'
import NewPassword from './components/NewPassword/NewPassword'
import CodeActivationScreen from './components/VerificationScreen/VerificationScreen'
import Navbar from './components/navbar/Navbar'
import RecruiterMenu from './components/Recruiter/Home/RecruiterMenu'
import CandidateForm from './components/Recruiter/CandidateForm/CandidateForm'
import BlurScreen from './components/Templates/BlurScreen/BlurScreen'
import FormDialog from './components/Templates/Saveform/FormDialog'
import CandidatesScreen from './components/CandidatesMenu/CandidatesScreen'
import NewJobOpening from './components/NewJobOpening/NewJobOpening'
import { FetchCreateJob } from './components/Contexts/CreateJobContext'
import Spinner from './components/Spinner/Spinner'
import SplashScreen from './components/Spinner/SplashScreen'
import UserCard from './components/Templates/UserCard.jsx/UserCard'
import Settings from './components/Settings/Settings'
import ManageProfiles from './components/Settings/ManageProfiles/ManageProfiles'
import Profile from './components/Settings/Profile/Profile'
import { SET_REFRESHED_AUTH_TOKEN } from './UserContext/UserReducer'
import refreshAuthToken from './services/Refresh/refresh.service.ts'
import CandidatesList from './components/CandidatesList/CandidatesList'
import { returnCandidateContext } from './components/Contexts/CandidatesContext'
import usePageTracking from './utils/ScrollTracking'
import EditJobOpening from './components/NewJobOpening/EditJobOpening'

const App = () => {
  const showNavbar = useSelector((state) => state.user.showBlurScreen)
  const userState = useSelector((state) => state.user)

  const { getInitialData } = returnCandidateContext()

  const [handleLogin, setHandleLogin] = useState()
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false)
  const [isSpinnerActive, setIsSpinnerActive] = useState(false)
  const [isLoadingScreenActive, setIsLoadingScreenActive] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingScreenActive(false)
    }, 3000)
  }, [])

  const getFormBodyContent = () => {
    switch (showNavbar) {
      case 'PROFILE_VIEW_MORE': return <UserCard />
      default: return <FormDialog isOkay={false} />
    }
  }

  const handleRefreshToken = async () => {
    if (userState.refreshToken) {
      const [statusCode, response] = await refreshAuthToken(userState.refreshToken)
      if (statusCode === 200) return response.AuthenticationResult.AccessToken
    }
    return userState.authToken
  }

  // User is already loggedIn
  useEffect(() => {
    if (userState.isActive && userState.refreshToken) {
      setIsSpinnerActive(true)

      handleRefreshToken().then(async (response) => {
        const newToken = response
        dispatch(SET_REFRESHED_AUTH_TOKEN({ authToken: newToken }))
        await getInitialData(newToken)
        setIsTokenRefreshed(true)
        setIsSpinnerActive(false)
      })
    }
  }, [])

  // User logged in
  useEffect(async () => {
    if (handleLogin) {
      setIsSpinnerActive(true)
      await getInitialData(userState.authToken)
      setIsTokenRefreshed(true)
      setIsSpinnerActive(false)

      const timer = setInterval(() => handleRefreshToken(), 1000 * 60 * 60) // EVERY HOUR
      return () => {
        clearInterval(timer)
      }
    }
    return []
  }, [handleLogin])

  return (
    <div className="app-wrapper">
      {showNavbar ? (
        <BlurScreen>
          {
            getFormBodyContent()
          }
        </BlurScreen>
      ) : ''}
      <Navbar />
      { usePageTracking() }
      {isSpinnerActive ? <Spinner /> : ''}
      {isLoadingScreenActive ? <SplashScreen /> : ''}
      <div className="side-content">
        <Routes>
          {
            !userState.isActive
              ? (
                <>
                  <Route
                    path="/"
                    element={(
                      <Login
                        setIsSpinnerActive={setIsSpinnerActive}
                        setHandleLogin={setHandleLogin}
                      />
                    )}
                  />
                  <Route
                    path="/forgotPassword"
                    element={(
                      <ResetPassword />
                    )}
                  />
                  <Route
                    path="/verification"
                    element={(
                      <CodeActivationScreen />
                    )}
                  />
                  <Route
                    path="/newPassword"
                    element={(
                      <NewPassword />
                    )}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              )
              : ''
          }
          {
            userState.isActive && isTokenRefreshed && userState.userGroup.includes('Admin')
              ? (
                <>
                  <Route
                    path="/home"
                    element={(
                      <RecruiterMenu setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                  />
                  <Route
                    path="/candidates"
                    element={(
                      <CandidatesScreen setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                  />
                  <Route
                    path="/allcandidates"
                    element={(
                      <CandidatesList setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                  />
                  <Route
                    path="/recruiter/form"
                    element={(
                      <CandidateForm setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                  />
                  <Route
                    path="/admin/newJob"
                    element={(
                      <FetchCreateJob>
                        <NewJobOpening setIsSpinnerActive={setIsSpinnerActive} />
                      </FetchCreateJob>
                    )}
                  />
                  <Route
                    path="/admin/editJob"
                    element={(
                      <FetchCreateJob>
                        <EditJobOpening setIsSpinnerActive={setIsSpinnerActive} />
                      </FetchCreateJob>
                    )}
                  />
                  <Route
                    path="/settings"
                    element={(
                      <Settings />
                    )}
                  >
                    <Route
                      path="/settings/profile"
                      element={(
                        <Profile />
                      )}
                    />
                    <Route
                      path="/settings/manage/accounts"
                      element={(
                        <ManageProfiles />
                      )}
                    />
                    <Route path="*" element={<Navigate to="/settings/profile" />} />
                  </Route>

                  <Route path="*" element={<Navigate to="/home" />} />
                </>
              ) : ''
          }
          {
             userState.isActive && isTokenRefreshed && !userState.userGroup.includes('Admin')
               ? (
                 <>
                   <Route
                     path="/home"
                     element={(
                       <RecruiterMenu setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                   />
                   <Route
                     path="/candidates"
                     element={(
                       <CandidatesScreen setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                   />
                   <Route
                     path="/allcandidates"
                     element={(
                       <CandidatesList setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                   />
                   <Route
                     path="/recruiter/form"
                     element={(
                       <CandidateForm setIsSpinnerActive={setIsSpinnerActive} />
                    )}
                   />
                   <Route
                     path="/settings"
                     element={(
                       <Settings />
                    )}
                   >
                     <Route
                       path="/settings/profile"
                       element={(
                         <Profile />
                      )}
                     />
                   </Route>
                   <Route path="*" element={<Navigate to="/home" />} />
                 </>
               ) : ''
          }
        </Routes>
      </div>
    </div>
  )
}
export default App
