/* eslint-disable max-len */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react'
import { useSelector } from 'react-redux'
import { returnCandidateContext } from './CandidatesContext'
import getAllSections from '../../services/Sections/allsections.service.ts'
import generalForm from '../../../assets/jsons/generalForm.json'
import { loggedUser } from '../../UserContext/UserReducer'

const ACTIONS = {
  UPDATE: 'update',
  RESET: 'reset',
}

const initVals = {
  defaultSections: [],
  existingJobForms: undefined,
  fullSections: [],
  selectedSectionsIds: [],
}

const reduce = (state, event) => {
  switch (event.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        ...event.payload,
      }
    case ACTIONS.RESET:
      return initVals
    default:
      return state
  }
}

const CreateJobContext = createContext({
  ...initVals,
})

export const FetchCreateJob = ({ children }) => {
  const {
    countriesJson,
    clientsJson,
    companiesJson,
    jobTypesJson,
    candidateTemplateModel,
  } = returnCandidateContext()
  const [state, dispatch] = useReducer(reduce, initVals)

  const userState = useSelector(loggedUser)

  const setSelectedSectionsIds = (sections) => {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        selectedSectionsIds: sections,
      },
    })
  }

  const setFullSections = (sections) => {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        fullSections: sections,
      },
    })
  }

  useEffect(() => {
    if (!state.existingJobForms) return
    const selectedSectionsTemplate = []
    state.selectedSectionsIds.map((item) => {
      if (state.fullSections.findIndex((alreadySelected) => alreadySelected.id === item) === -1) {
        selectedSectionsTemplate.push(
          {
            isExistingTemplate: true,
            ...state.existingJobForms.find((existingJobTemplates) => existingJobTemplates.id === item),
          },
        )
      }
      return selectedSectionsTemplate
    })
    setFullSections([...state.fullSections, ...selectedSectionsTemplate])
  }, [state.selectedSectionsIds])

  const editSection = (sectionID, question) => {
    const sectionToEdit = state.fullSections.find((item) => item.id === sectionID.id)
    sectionToEdit.questions.push({
      isNewQuestion: true,
      id: `${sectionID.id}-${sectionToEdit.questions.length}`,
      label: 'Write the question to ask...',
      options: [{
        id: 0,
        value: 'Default',
      }],
      type: question.type,
      isMandatory: 0,
      status: 1,
    })
  }

  const handleReplaceGeneralOptions = (id, dataToAssign) => {
    // eslint-disable-next-line array-callback-return
    generalForm[0].questions.find((item) => {
      if (item.id === id) {
        // eslint-disable-next-line no-param-reassign
        item.options = dataToAssign
      }
    })
  }

  const getExistingJobForms = async () => {
    const [status, res] = await getAllSections(userState.authToken)
    if (status === 200) {
      return res
    }
    return []
  }

  const fillDefaultSection = (selectedJob) => {
    generalForm[0].questions[0].answer = selectedJob.clientID
    generalForm[0].questions[1].answer = selectedJob.name
    generalForm[0].questions[2].answer = selectedJob.countryID
    generalForm[0].questions[3].answer = selectedJob.companyID
    generalForm[0].questions[4].answer = selectedJob.typeID
    generalForm[0].questions[5].answer = selectedJob.internalCode
    generalForm[0].questions[6].answer = selectedJob.rateMin
    generalForm[0].questions[7].answer = selectedJob.rateMax
    generalForm[0].questions[8].answer = selectedJob.jobDescriptorPath

    generalForm[0].questions[9].answer = new Date(
      selectedJob.openDate,
    ).toDateString()
    generalForm[0].questions[10].answer = new Date(
      selectedJob.limitDate,
    ).toDateString()

    generalForm[0].questions[11].answer = selectedJob.expectedRegionProfile
    generalForm[0].questions[12].answer = selectedJob.description
    generalForm[0].questions[13].answer = selectedJob.englishRequired
  }

  const getInitialData = async (selectedJob) => {
    handleReplaceGeneralOptions('countryID', countriesJson)
    handleReplaceGeneralOptions('clientID', clientsJson)
    handleReplaceGeneralOptions('companyID', companiesJson)
    handleReplaceGeneralOptions('typeID', jobTypesJson)

    const res = await getExistingJobForms()

    if (selectedJob) {
      fillDefaultSection(selectedJob)
      // setSelectedSectionsIds(selectedJob.form.sections.map((obj) => obj.id))

      // for (let i = 1; i < selectedJob.form.sections.length; i += 1) {
      //   const section = selectedJob.form.sections[i]
      //   section.isNewSection = true
      //   section.isEditingForm = true
      //   section.questions.forEach((question) => {
      //     // eslint-disable-next-line no-param-reassign
      //     question.isNewQuestion = true
      //     // eslint-disable-next-line no-param-reassign
      //     question.isEditingForm = true
      //   })
      // }

      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          defaultSections: generalForm,
          fullSections: [...generalForm, ...selectedJob.form.sections],
          existingJobForms: res,
        },
      })
    } else {
      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          defaultSections: generalForm.concat(candidateTemplateModel),
          fullSections: generalForm.concat(candidateTemplateModel),
          existingJobForms: res,
        },
      })
    }
  }

  return (
    <CreateJobContext.Provider value={{
      ...state,
      setFullSections,
      setSelectedSectionsIds,
      editSection,
      getInitialData,
    }}
    >
      { children }
    </CreateJobContext.Provider>
  )
}

export const returnCreateJobContext = () => {
  const context = useContext(CreateJobContext)
  if (!context) return 'There is not context'
  return context
}
