import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useNavigate, Outlet,
} from 'react-router-dom'
import { SET_BLUR_SCREEN, loggedUser } from '../../UserContext/UserReducer'
import Button from '../button/Button'
import defaultIcon from '../../../assets/images/settingsProfile.svg'
import './settings.scss'

const Settings = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector(loggedUser)

  const handleProfileClick = () => {
    dispatch(SET_BLUR_SCREEN({
      showBlurScreen: 'PROFILE_VIEW_MORE',
    }))
  }
  return (
    <div className="settings-wrapper">
      <div className="settings-left-side">
        <Button classButton="profile-button" text={userState.name} icon={[defaultIcon]} onClick={() => handleProfileClick()} />
        <div className="tabs-container">
          <Button text="Profile" onClick={() => navigate('/settings/profile')} buttonType="sub-mneu" />
          <Button text="Manage Accounts" onClick={() => navigate('/settings/manage/accounts')} buttonType="sub-mneu" />
        </div>
      </div>
      <div className="settings-content-side">
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
