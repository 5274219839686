import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './alphabeticalList.scss'
import Button from '../button/Button'
import BlurScreen from '../Templates/BlurScreen/BlurScreen'
import RenderForm from '../Templates/RenderForm/RenderForm'
import SearchInput from '../Templates/SearchInput/SearchInput'

const AlphabeticalList = ({ list, setIsSpinnerActive }) => {
  const navigate = useNavigate()
  const [nameList, setNameList] = useState([])
  const [initialList, setInitialList] = useState([])

  const [sorted, setSorted] = useState([])
  const [showSectionPreview, setShowSectionPreview] = useState(false)
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    setIsSpinnerActive(true)
  }, [])

  useEffect(() => {
    if (list.length === 0) return
    if (list[0].name === '') return

    const nameLastname = [...list].map((item) => ({
      id: item.id,
      name: item.section.questions[0].answer[0],
      lastname: item.section.questions[1].answer[0],
    }))
    setNameList([...nameLastname])
    setInitialList([...nameLastname])
  }, [list])

  useEffect(async () => {
    if (nameList.length === 0) return
    if (nameList) {
      const sortedList = await [...nameList].sort(
        (a, b) => a.name.localeCompare(b.name),
      )

      const data = sortedList.reduce((r, e) => {
        try {
          // get first letter of name of current element
          const group = e.lastname.charAt(0) || ''
          // if there is no property in accumulator with this letter create it
          // eslint-disable-next-line no-param-reassign
          if (!r[group]) r[group] = { group, children: [e] }
          // if there is push current element to children array for that letter
          else r[group].children.push(e)
          // return accumulator
          return r
        } catch (e2) {
          return []
        }
      }, {})

      // since data at this point is an object, to get array of values
      // we use Object.values method
      const result = Object.values(data)

      setSorted(result)
    }
  }, [nameList])

  const createCandidateSection = () => {
    const candidate = { ...list.find((x) => x.id === showSectionPreview) }
    return [candidate.section]
  }

  useEffect(() => {
    if (sorted.length > 0) {
      setIsSpinnerActive(false)
    }
  }, [sorted])

  return (
    <div className="form-wrapper">
      <SearchInput
        attributeToFilter={['name', 'lastname']}
        inputClass="full-container"
        initialList={initialList}
        listToFilter={nameList}
        setFilter={setNameList}
      />

      <div className="letters-container">
        {sorted && sorted.map((item) => (
          <div className="alphabetical-list" key={item.group}>
            <div className="alphabet-letter">
              {item.group}
            </div>
            {
          item.children.map((candidate) => (
            <div className="candidate-name" key={candidate.id}>
              <input type="radio" onChange={() => setSelectedItem(candidate)} id={candidate.id} name="applicant" />
              <label htmlFor={candidate.id}>
                <p>{candidate.name}</p>
                <p>{candidate.lastname}</p>
              </label>
              <Button
                classButton="eye-container"
                onClick={() => setShowSectionPreview(candidate.id)}
              />
            </div>
          ))
        }
          </div>
        ))}
      </div>

      <div className="form-buttons">
        <Button
          text="Cancel"
          classButton="cancel-button"
          onClick={() => navigate('/home')}
        />
        <Button
          text="Use"
          classButton="accept-button"
          onClick={() => navigate('/recruiter/form', {
            state: {
              applicant: selectedItem,
              newIntervieew: true,
            },
          })}
        />
      </div>

      {
      showSectionPreview
        ? (
          <BlurScreen>
            <RenderForm
              sections={createCandidateSection()}
              closeFunction={() => setShowSectionPreview(undefined)}
              showSectionAsText={Array(
                list.find((x) => x.id === showSectionPreview).length,
              )
                .fill(null).map((_, i) => i)}
            />
          </BlurScreen>
        )
        : ''
      }

    </div>
  )
}

AlphabeticalList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
      lastname: PropTypes.string,
    }),
  ),
  setIsSpinnerActive: PropTypes.func,
}

AlphabeticalList.defaultProps = {
  list: [
    {
      id: '',
      name: '',
    },
  ],
  setIsSpinnerActive: () => {},
}

export default AlphabeticalList
