/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './input.scss'
import Checkbox from './Checkbox'
import PopUpEditInput from '../../popup/PopUp'
import Button from '../../button/Button'
import remove from '../../../../assets/images/createField/remove.png'

const Textbox = ({
  label, defaultValue, rows,
  textboxName, initialItemList, setChanges,
  disabled, isEditing, isSelectable,
  isNeeded, validateChecked, parentId, openPopUp,
  showRemove, handleRemoveItem,
  setChangesByIndex, changesByIndex,
}) => {
  const [itemList, setItemList] = useState(initialItemList || [{ value: '' }])
  const [showPopUp, setShowPopUp] = useState(false)

  const handleCheckboxChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    const { value, checked } = e.target

    if (isSelectable && parentId) {
      const selectedElements = validateChecked[parentId]
      if (checked) {
        selectedElements.push(textboxName.toString())
      } else {
        const index = selectedElements.indexOf(textboxName.toString())
        if (index !== -1) {
          selectedElements.splice(index, 1)
        }
      }
    }
  }

  const handleItemChange = (e, index) => {
    const { name, value } = e.target
    const list = [...itemList]
    list[index][name] = value

    if (setChanges) {
      setChanges(list)
    } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
      setItemList(list)
      setChangesByIndex(changesByIndex, [{
        [textboxName]: value,
      }], textboxName)
    } else {
      setItemList(list)
    }
  }

  return (
    <div className={`textbox-container ${showPopUp ? 'selected' : ''}`}>
      <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
        {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={label}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={textboxName}>
                        {showRemove
                          && (
                          <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                          ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }

        {
          isEditing
            ? (
              <textarea
                name={textboxName}
                rows={rows}
                cols="50"
                onChange={(e) => handleItemChange(e, 0)}
                defaultValue={defaultValue || itemList[0][textboxName]}
                disabled={disabled}
              />
            )
            : <span>{itemList[0][textboxName]}</span>
        }
      </div>
      {
        showPopUp
          ? (
            <PopUpEditInput
              defaultValue={label}
              type="textbox"
              parentId={parentId}
              childId={textboxName}
              closePopup={() => setShowPopUp(false)}
            />
          ) : ''
      }
    </div>
  )
}

Textbox.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  rows: PropTypes.string,
  initialItemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.string,
    }),
  ),
  textboxName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setChanges: PropTypes.func,
  isEditing: PropTypes.bool,
  disabled: PropTypes.bool,
  isSelectable: PropTypes.bool,
  validateChecked: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  isNeeded: PropTypes.bool,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  openPopUp: PropTypes.bool,
  showRemove: PropTypes.bool,
  handleRemoveItem: PropTypes.func,
  setChangesByIndex: PropTypes.func,
  changesByIndex: PropTypes.number,
}

Textbox.defaultProps = {
  label: undefined,
  defaultValue: '',
  rows: '5',
  initialItemList: undefined,
  setChanges: undefined,
  isEditing: false,
  disabled: false,
  isSelectable: false,
  validateChecked: [],
  isNeeded: false,
  parentId: undefined,
  openPopUp: false,
  showRemove: false,
  handleRemoveItem: () => {},
  setChangesByIndex: undefined,
  changesByIndex: undefined,
}

export default Textbox
