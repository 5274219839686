/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './selector.scss'
import '../FormsElement/input.scss'
import PopUpEditInput from '../../popup/PopUp'
import Button from '../../button/Button'
import remove from '../../../../assets/images/createField/remove.png'
import Checkbox from '../FormsElement/Checkbox'

const Selector = ({
  items, icon, setChanges, initialItemList,
  iconAlt, label, isNeeded,
  openPopUp, parentId, selectorName,
  showRemove, handleRemoveItem, extraClass,
  isEditing, extraSelectorClass, defaultIndex,
  handleChange, disabled, setChangesByIndex, changesByIndex,
  isSelectable, validateChecked,
}) => {
  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    if (initialItemList) {
      const list = [...initialItemList]
      list[0][selectorName] = items[0]?.id || items[0]

      if (setChanges) {
        setChanges(list)
      } else if (setChangesByIndex && changesByIndex) {
        setChangesByIndex(changesByIndex, [{
          [selectorName]: list[0][selectorName],
        }], selectorName)
      }
    }
  }, [])

  const handleItemChange = (e) => {
    const { name, value } = e.target

    if (handleChange) {
      handleChange(value)
    } else if (initialItemList) {
      const list = [...initialItemList]
      list[0][name] = value

      if (setChanges) {
        setChanges(list)
      } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
        setChangesByIndex(changesByIndex, [{
          [selectorName]: value,
        }], selectorName)
      }
    }
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target

    if (isSelectable && parentId) {
      const selectedElements = validateChecked[parentId]
      if (checked) {
        selectedElements.push(selectorName.toString())
      } else {
        const index = selectedElements.indexOf(selectorName.toString())
        if (index !== -1) {
          selectedElements.splice(index, 1)
        }
      }
    }
  }

  return (
    items && items.length > 0
      ? (
        <div className={`${extraClass} input-full-container ${showPopUp ? 'selected' : ''}`}>
          <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
            {
              // eslint-disable-next-line no-nested-ternary
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={selectorName}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={label}>
                        {showRemove
                        && (
                        <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                        ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }
            {
          isEditing
            ? (
              <div className={`${extraSelectorClass} region-dropdown-wrapper drop`}>
                { icon ? <img src={icon} alt={iconAlt} /> : '' }
                <select
                  defaultValue={Array.isArray(defaultIndex) ? defaultIndex[0] : defaultIndex}
                  id="regions"
                  name={selectorName}
                  className="select-regions"
                  onChange={handleItemChange}
                  disabled={disabled}
                >
                  {
                     items.map((item) => (
                       item.value !== undefined
                         ? <option value={item.id} key={item.id}>{item.value}</option>
                         : <option value={item} key={item}>{item}</option>))
                  }
                </select>
              </div>
            )
            : (
              <div className="input-container">
                {
                    items[0]?.value
                      ? <span>{items[0].value}</span>
                      : <span>{items[0]}</span>
                }
              </div>
            )
        }
          </div>
          {
        showPopUp
          ? (
            <PopUpEditInput
              defaultValue={label}
              type="selector"
              parentId={parentId}
              childId={selectorName}
              closePopup={() => setShowPopUp(false)}
            />
          ) : ''
      }
        </div>
      )
      : ''
  )
}

Selector.propTypes = {
  label: PropTypes.string,
  setChanges: PropTypes.func,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.string,
    })),
  ]),
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  isNeeded: PropTypes.bool,
  openPopUp: PropTypes.bool,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectorName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showRemove: PropTypes.bool,
  handleRemoveItem: PropTypes.func,
  extraClass: PropTypes.string,
  extraSelectorClass: PropTypes.string,
  defaultIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  isEditing: PropTypes.bool,
  initialItemList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        value: PropTypes.string,
      }),
    ),
  ]),
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  setChangesByIndex: PropTypes.func,
  changesByIndex: PropTypes.number,
  validateChecked: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  isSelectable: PropTypes.bool,
}

Selector.defaultProps = {
  items: [{
    id: '',
    value: '',
  }],
  setChanges: undefined,
  label: '',
  icon: undefined,
  iconAlt: '',
  isNeeded: false,
  openPopUp: false,
  parentId: undefined,
  selectorName: undefined,
  showRemove: false,
  handleRemoveItem: () => {},
  extraClass: '',
  extraSelectorClass: '',
  defaultIndex: 0,
  isEditing: true,
  initialItemList: undefined,
  handleChange: undefined,
  disabled: false,
  setChangesByIndex: undefined,
  changesByIndex: undefined,
  validateChecked: [],
  isSelectable: false,
}

export default Selector
