/* eslint-disable max-len */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { loggedUser } from '../../../UserContext/UserReducer'
import FormTitle from '../../Templates/FormsTitle/FormTitle'
import Input from '../../Templates/FormsElement/Input'
import Button from '../../button/Button'
import './profile.scss'
import '../../Recruiter/CandidateForm/candidateForm.scss'

const Profile = () => {
  const userState = useSelector(loggedUser)
  const [formData, setFormData] = useState([{
    name: 'Leo',
    lastname: 'Rosales',
    phone: '12345678',
    email: userState.name,
    password: '......',
  }])

  const [generalInformationData, setGeneralInformationDate] = useState(formData)
  return (
    <div className="profile-wrapper form-wrapper">
      <FormTitle divClassName="settings-title" title="Profile Settings" />
      <Input
        initialItemList={generalInformationData}
        setChanges={setGeneralInformationDate}
        label="Name"
        inputName="name"
        isEditing
      />
      <Input
        initialItemList={generalInformationData}
        setChanges={setGeneralInformationDate}
        label="Lastname"
        inputName="lastname"
        isEditing
      />
      <Input
        initialItemList={generalInformationData}
        setChanges={setGeneralInformationDate}
        label="Phone No. "
        inputName="phone"
        isEditing
      />
      <Input
        initialItemList={generalInformationData}
        setChanges={setGeneralInformationDate}
        label="Email"
        inputName="email"
        isEditing
      />
      <Input
        initialItemList={formData}
        setChanges={setFormData}
        label="Password"
        inputName="password"
      />
      <Button
        onClick={() => {}}
        text="Change Password"
        classButton="change-password-button"
      />
    </div>
  )
}

export default Profile
