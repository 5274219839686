import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import KanBanColumnElement from '../KanBanColumnElement/KanBanColumnElement'
import './kanBanColumn.scss'
import BlurScreen from '../BlurScreen/BlurScreen'
import FormTitle from '../FormsTitle/FormTitle'
import Feedback from '../../Feedback/Feedback'
import Button from '../../button/Button'
import closeIcon from '../../../../assets/images/close.svg'

const cardColors = [
  { header: 'kanban-element-header-green', body: 'kanban-body-header-green' },
  { header: 'kanban-element-header-red', body: 'kanban-body-header-red' },
  { header: 'kanban-element-header-yellow', body: 'kanban-body-header-yellow' },
  { header: 'kanban-element-header-gray', body: 'kanban-body-header-gray' },
]

// GET MONTHS TIMELAPSE TO DEFINE TAG COLOR
const handleTimeLapse = (creationDate) => {
  let months = 0
  const formatedDate = new Date(creationDate)
  const currenDate = new Date()
  months = (currenDate.getFullYear() - formatedDate.getFullYear()) * 12
  months -= formatedDate.getMonth()
  months += currenDate.getMonth()
  return Math.floor((Math.abs(months) / 4)) > 3 ? 3 : Math.floor((Math.abs(months) / 4))
}

const KanBanColumn = ({
  columnID,
  columnTitle,
  applicants,
  groupColumns,
}) => {
  const [candidateToUpdate, setCandidateToUpdate] = useState(undefined)
  const navigate = useNavigate()

  const addCard = (feedback) => {
    const model = {
      statusID: columnID,
      processID: candidateToUpdate.id,
      feedback,
    }
    applicants.push(candidateToUpdate)
    groupColumns(candidateToUpdate.id, candidateToUpdate.column, model)
    setCandidateToUpdate(undefined)
  }

  const manageDrop = (item) => {
    if (item.column) setCandidateToUpdate(item)
    else {
      navigate('/home', {
        state: {
          applicant: item.applicant,
        },
      })
    }
  }

  const [, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => manageDrop(item),
  }))

  return (
    <>
      <div className="kanban-column-wrapper" ref={drop}>
        <span className="kanban-column-title">{columnTitle}</span>
        <div className="kanban-column-cards">
          {
        applicants?.map((applicant) => (
          <KanBanColumnElement
            key={`kanban-element-${applicant.applicant.id}-${applicant.id || 0}`}
            applicant={applicant}
            aplicantColumn={columnTitle}
            randomColor={cardColors[handleTimeLapse(applicant.createdAt)]}
            blockDrag={applicant.jobOpening === undefined}
          />
        ))
      }
        </div>
      </div>
      {
      candidateToUpdate
        ? (
          <BlurScreen className="container-feedback">
            <Button classButton="close-form" icon={[closeIcon]} onClick={() => setCandidateToUpdate(undefined)} />
            <FormTitle title="Feedback" />
            <Feedback
              onEnter={addCard}
              pastFeedback={candidateToUpdate.feedback !== null ? candidateToUpdate.feedback : []}
              processID={candidateToUpdate.id}
              applicantID={candidateToUpdate.applicant.id}
            />
          </BlurScreen>
        )
        : ''
      }
    </>
  )
}

KanBanColumn.propTypes = {
  columnTitle: PropTypes.string,
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  groupColumns: PropTypes.func,
  columnID: PropTypes.number,
}

KanBanColumn.defaultProps = {
  columnTitle: '',
  applicants: [],
  groupColumns: () => {},
  columnID: -1,
}

export default KanBanColumn
