/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

// eslint-disable-next-line import/prefer-default-export
export const userLoggedSlice = createSlice({
  name: 'user',
  authToken: 'token',
  refreshToken: 'token',
  sessionToken: 'token',
  userGroup: 'user',
  isActive: 'active',
  showBlurScreen: 'blur',
  initialState: {
    name: null,
    authToken: null,
    refreshToken: null,
    sessionToken: null,
    isActive: false,
    showBlurScreen: undefined,
    userGroup: [],
  },
  reducers: {
    SET_USER_LOGGED: (state, action) => {
      state.name = action.payload.name
      state.authToken = action.payload.authToken
      state.refreshToken = action.payload.refreshToken
      state.isActive = action.payload.isActive
      state.showBlurScreen = undefined
      state.userGroup = action.payload.userGroup
    },
    SET_USER_CHALLENGED: (state, action) => {
      state.name = action.payload.name
      state.sessionToken = action.payload.sessionToken
    },
    SET_USER_LOGGED_OUT: (state) => {
      state.name = null
      state.authToken = null
      state.refreshToken = null
      state.isActive = false
      state.showBlurScreen = false
      state.sessionToken = null
      state.userGroup = []
    },
    SET_REFRESHED_AUTH_TOKEN: (state, action) => {
      state.authToken = action.payload.authToken
    },
    SET_BLUR_SCREEN: (state, action) => {
      state.showBlurScreen = action.payload.showBlurScreen
    },
  },
})

export const {
  SET_USER_LOGGED,
  SET_USER_LOGGED_OUT,
  SET_USER_CHALLENGED,
  SET_REFRESHED_AUTH_TOKEN,
  SET_BLUR_SCREEN,
} = userLoggedSlice.actions
export const loggedUser = (state) => state.user

/* In order to get the data from the current user you ought to
import useSelector and loggedUser. Once done the previous assign as per shown below:

let data = useSelector(loggedUser)

You will get both the username and authToken as an object

*/

export default userLoggedSlice.reducer
