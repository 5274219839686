import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loggedUser } from '../../UserContext/UserReducer'
import Button from '../button/Button'
import RenderForm from '../Templates/RenderForm/RenderForm'
import grayPlus from '../../../assets/images/createField/gray-plus.svg'
import ExistingForms from './ExistingForms'
import BlurScreen from '../Templates/BlurScreen/BlurScreen'
import FormDialog from '../Templates/Saveform/FormDialog'

import { createJobOpening } from '../../services/JobOpenings/jobOpenings.service.ts'
import { returnCreateJobContext } from '../Contexts/CreateJobContext'

const NewJobOpening = ({ setIsSpinnerActive }) => {
  const navigate = useNavigate()
  const userState = useSelector(loggedUser)
  const {
    setFullSections,
    fullSections,
    getInitialData,
    selectedSectionsIds,
  } = returnCreateJobContext()
  const [selectedQuestions, setSelectedSection] = useState({})
  const [finalFormData, getFormData] = useState(undefined)
  const [showExistingForms, setShowExistingForms] = useState(false)

  const [showBlurScreen, setShowBlurScreen] = useState()
  const [isOkay, setIsOkay] = useState()

  const okayMsg = 'The JO was saved successfully!'
  const [errorMsg, setErrorMsg] = useState('Oops! The JO was not saved!')

  useEffect(async () => {
    if (fullSections.length === 0) {
      setIsSpinnerActive(true)
      await getInitialData()
      setIsSpinnerActive(false)
    }
  }, [])

  useEffect(() => {
    if (!userState.authToken && !userState.isActive) navigate('/')
  }, [userState])

  const addNewSection = () => {
    fullSections.push({
      id: `newsection-${fullSections.length}`,
      isNewSection: true,
      title: '',
      questions: [],
    })
    setFullSections([...fullSections])
  }

  const getFormDataByIndex = (index, changes, inputName) => {
    if (inputName === 'title') {
      fullSections[index][inputName] = changes[0][inputName]
    }
    finalFormData[index] = [{
      ...finalFormData[index][0],
      ...changes[0],
    }]
    getFormData(finalFormData)
  }

  const handleRemoveItem = (itemIndex, parentIndex, isParent, sectionID = -1) => {
    if (isParent) {
      fullSections.splice(itemIndex, 1)
      if (sectionID !== -1) {
        const index = selectedSectionsIds.indexOf(sectionID)
        selectedSectionsIds.splice(index, 1)
      }
    } else {
      const selectedSection = fullSections[parentIndex].questions
      selectedSection.splice(itemIndex, 1)
    }
    setFullSections([...fullSections])
  }

  const saveSelection = async () => {
    const model = cloneDeep(finalFormData)[0]
    const modelSections = cloneDeep(fullSections)
    // DELETE UNNECESARY ID
    delete model['general-1']
    delete model.title

    const generalInfoSection = model[0]
    const generalInfoSectionQuestions = modelSections[0].questions
    // MAKE SURE DATA IS PARSED TO NUMBER
    generalInfoSection.rateMin = parseFloat(generalInfoSection.rateMin)
    generalInfoSection.rateMax = parseFloat(generalInfoSection.rateMax)
    generalInfoSection.clientID = parseFloat(generalInfoSection.clientID)
    generalInfoSection.countryID = parseFloat(generalInfoSection.countryID)
    generalInfoSection.companyID = parseFloat(generalInfoSection.companyID)
    generalInfoSection.typeID = parseFloat(generalInfoSection.typeID)

    if (generalInfoSection.openDate === '') delete generalInfoSection.openDate
    if (generalInfoSection.limitDate === '') delete generalInfoSection.limitDate

    const filledJO = generalInfoSectionQuestions.every((question) => {
      if (question.isMandatory && generalInfoSection[question.id] === '') {
        setErrorMsg('Oops! Not all required data was filled')
        setIsOkay(false)
        setShowBlurScreen(true)
        return false
      }
      return true
    })
    if (!filledJO) return

    // IF IT IS NEW SECTION DELETE id
    modelSections.forEach((section, index) => {
      if (!section.isExistingTemplate) {
        delete modelSections[index].id
      }
    })

    // IGNORE FIRST TWO SECTIONS
    const formatSections = modelSections.slice(2)

    formatSections.forEach((value, index) => {
      const selectedSection = selectedQuestions[formatSections[index].id]
      formatSections[index].questions = (value.questions?.map((question) => {
        // OBJECT FORMAT WHENEVER THE QUESTION ALREADY EXISTS
        if (value.isExistingTemplate) {
          if (selectedSection.includes((`${formatSections[index].id}-${question.questionID}`))) {
            return ({
              id: question.questionID,
              isMandatory: question.isMandatory,
              status: question.status,
            })
          }
          return undefined
        }
        // OBJECT FORMAT WHENEVER IT IS A NEW QUESTIONS
        return ({
          isMandatory: question.isMandatory,
          status: question.status,
          options: question.options.map((option) => option.value),
          placeholder: question.placeholder,
          type: question.type,
          label: question.label,
        })
      }))

      formatSections[index].questions = formatSections[index].questions.filter(
        (item) => item !== undefined,
      )
    })

    const filledNewSections = formatSections.every((section) => {
      if (section.title === '') return false

      const questions = section.questions.every((question) => {
        if (question.label === '') return false
        return true
      })
      return questions
    })

    if (!filledNewSections) {
      setErrorMsg('Oops! Some titles/questions are empty')
      setIsOkay(false)
      setShowBlurScreen(true)
      return
    }

    // JOB OPENING TO POST
    const jobToPost = {
      ...generalInfoSection,
      recruiter: userState.name,
      form: {
        name: generalInfoSection.name,
        status: 1,
        sections: formatSections || [],
      },
    }

    const [statusCode, error] = await createJobOpening(userState.authToken, jobToPost)
    setErrorMsg(error?.message || 'Oops! JO was not saved')
    setIsOkay(statusCode === 200)
    setShowBlurScreen(true)
  }

  const onClose = () => {
    if (isOkay) navigate('/home')
    else setShowBlurScreen(false)
  }

  return (
    <>
      {showExistingForms
        ? <ExistingForms closeExistingForms={() => setShowExistingForms(false)} />
        : ''}

      <div className="form-wrapper new-job-opening" style={showExistingForms ? { display: 'none' } : { display: 'flex' }}>
        <div className="form-header-wrapper">
          <RenderForm
            sections={fullSections}
            enabledSection={[0]}
            handleRemoveItem={handleRemoveItem}
            getFormData={getFormData}
            getFormDataByIndex={getFormDataByIndex}
            setSelectedQuestions={setSelectedSection}
            selectedQuestions={selectedQuestions}
          />
          <div className="form-body-row">
            <Button
              onClick={addNewSection}
              text="Add new section"
              classButton="new-section-button"
              icon={[grayPlus]}
            />
            <Button
              onClick={() => setShowExistingForms(true)}
              text="Use existing section"
              classButton="jobopening-button"
            />
          </div>

          <div className="form-buttons">
            <Button
              text="Cancel"
              classButton="cancel-button"
              onClick={() => navigate('/home')}
            />
            <Button
              text="Use"
              classButton="accept-button"
              onClick={saveSelection}
              type="submit"
            />
          </div>
        </div>
      </div>

      {
        showBlurScreen
          ? (
            <BlurScreen>
              <FormDialog
                isOkay={isOkay}
                onClose={onClose}
                okayMsg={okayMsg}
                errorMsg={errorMsg}
              />
            </BlurScreen>
          )
          : ''
      }
    </>
  )
}

NewJobOpening.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

NewJobOpening.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default NewJobOpening
