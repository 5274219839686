import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  SET_USER_LOGGED,
  SET_USER_CHALLENGED,
} from '../../UserContext/UserReducer'
import './loginStyles.scss'
import logo from '../../../assets/images/wotDevLogo.svg'
import logInUser from '../../services/login/LoginHandler.ts'
import InputComponent from '../Templates/Input/InputComponent'
import LoginFlowTitles from '../Templates/FlowTitles/LoginFlowTitles'
import Button from '../button/Button'

const Login = ({ setIsSpinnerActive, setHandleLogin }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [isLoginOk, setIsLoginOk] = useState(true)
  const dispatch = useDispatch()

  const handleUserChange = (value) => setUser(value)
  const handlePasswordChange = (value) => setPassword(value)

  const handleLogin = async () => {
    setIsSpinnerActive(true)
    const [statusCode, response] = await logInUser(user.trim(), password.trim())

    const isValid = statusCode === 200 && response?.AuthenticationResult
    if (isValid) {
      dispatch(SET_USER_LOGGED({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
        name: user,
        authToken: response.AuthenticationResult.AccessToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
        isActive: true,
        userGroup: response.groups,
      }))
      setHandleLogin(true)
    } else if (response?.ChallengeName && response?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(SET_USER_CHALLENGED({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
        name: user,
        sessionToken: response.Session,
      }))
      navigate('/newPassword')
    }
    setIsLoginOk(isValid)
    setIsSpinnerActive(false)
  }

  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <img src={logo} alt="Logo" className="logo" />
        <div className="form-body">
          <LoginFlowTitles classSelected="span-wrapper" title="Email" />
          <InputComponent
            valueToHandle={user}
            setValueToHandle={handleUserChange}
            isLoginOk={isLoginOk}
          />
          <LoginFlowTitles classSelected="span-wrapper" title="Password" />
          <InputComponent
            valueToHandle={password}
            setValueToHandle={handlePasswordChange}
            isPassword
            icon="/assets/images/Toggle.svg"
            iconToggled="/assets/images/unToggle.svg"
            isLoginOk={isLoginOk}
          />
          <div className="span-container">
            {
              (!isLoginOk)
                ? (
                  <LoginFlowTitles
                    classSelected="span-wrapper"
                    title="The username or password is not correct"
                    spanClass="forgot-control forgot-control-error"
                  />
                )
                : ''
            }
          </div>
          <input type="button" className="form-btn" value="Sign In" onClick={() => handleLogin()} />
          <div className="span-wrapper" style={{ margin: 'auto', width: 'fit-content' }}>
            <Button
              classButton="forgot-control password-reset"
              onClick={() => navigate('/forgotPassword')}
              text="Forgot your password?"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  setIsSpinnerActive: PropTypes.func,
  setHandleLogin: PropTypes.func.isRequired,
}

Login.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default Login
