import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getCandidates } from '../../services/Candidates/candidates.service.ts'
import { loggedUser } from '../../UserContext/UserReducer'
import AlphabeticalList from '../AlphabeticalList/AlphabeticalList'

const CandidatesList = ({ setIsSpinnerActive }) => {
  const userState = useSelector(loggedUser)
  const [candidates, setCandidates] = useState()

  useEffect(async () => {
    const [status, res] = await getCandidates(userState.authToken)
    if (status === 200) {
      setCandidates(res)
    }
  }, [])

  return (
    <AlphabeticalList setIsSpinnerActive={setIsSpinnerActive} list={candidates} />
  )
}

CandidatesList.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

CandidatesList.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default CandidatesList
