// --- TIME CONSTANTS
const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24

const formatToDays = (dateToFormat) => {
  const now = new Date()
  const distanceBetweenDates = dateToFormat - now
  return Math.floor(distanceBetweenDates / day).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
}

export const getRemainingDays = (eventDate) => formatToDays(new Date(eventDate))

export const formatDate = (date) => new Date(date).toISOString().split('T')[0]
