/* eslint-disable no-console */
import React, { useState } from 'react'
import PropType from 'prop-types'
import search from '../../../../assets/images/search.svg'
import '../Input/inputcomponent.scss'

const SearchInput = ({
  inputClass,
  spanClass,
  isShowingResultsRequired,
  listToFilter,
  attributeToFilter,
  setFilter,
  initialList,
}) => {
  const [searchValue, setSearchValue] = useState()

  const handleFilter = (e) => {
    setSearchValue(e)

    if (e === '') setFilter(initialList)
    else {
      const filtered = [...listToFilter].filter(
        (value) => {
          const filtering = []
          for (let i = 0; i < attributeToFilter.length; i += 1) {
            filtering.push(value[attributeToFilter[i]].toLowerCase().trim())
          }
          return filtering.toString().includes(e.toLowerCase().trim())
        },
      )
      setFilter(filtered)
    }
  }

  return (
    <div className="component-wrapper">
      <div className={`search-input-wrapper ${inputClass}`}>
        <img src={search} alt="InputIcon" className="left-input-icon" />
        <input placeholder="Search..." type="text" className={`input-icon ${inputClass}`} onChange={(e) => handleFilter(e.target.value)} />
      </div>

      {searchValue && searchValue.lenght > 0 && isShowingResultsRequired ? (
        <>
          <span className={`search-span ${spanClass}`}>showing results for </span>
          <span className={`search-filter ${spanClass}`} style={{ color: '#FFA611' }}>
            {`${searchValue}`}
          </span>
        </>
      ) : ''}
    </div>
  )
}

SearchInput.propTypes = {
  inputClass: PropType.string,
  spanClass: PropType.string,
  isShowingResultsRequired: PropType.bool,
  listToFilter: PropType.arrayOf(PropType.shape({
    id: PropType.oneOfType([
      PropType.string,
      PropType.number,
    ]),
  })).isRequired,
  initialList: PropType.arrayOf(PropType.shape({
    id: PropType.oneOfType([
      PropType.string,
      PropType.number,
    ]),
  })).isRequired,
  attributeToFilter: PropType.arrayOf(PropType.string).isRequired,
  setFilter: PropType.func.isRequired,
}

SearchInput.defaultProps = {
  inputClass: '',
  spanClass: '',
  isShowingResultsRequired: true,
}

export default SearchInput
