import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Tags from './Tags'

import tags from '../../../assets/jsons/tags.json'
import './TagsInput.scss'
import TagsSuggestions from './TagsSuggestions'
import Button from '../button/Button'

const TagsInput = ({ showSimilar }) => {
  const [similarResults, setSimilarResults] = useState([])
  const [currentTags, setCurrentTags] = useState([])
  const currentValue = useRef()
  const tagsRef = useRef()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter'
    && currentValue.current.value !== ''
    && !currentTags.includes(currentValue.current.value)) {
      currentTags.push({
        id: currentValue.current.value,
        tag: currentValue.current.value,
      })
      setCurrentTags([...currentTags])
      currentValue.current.value = ''
    }
  }

  const onChange = (e) => {
    const { value } = e.target
    const filtered = tags.filter(
      (tag) => tag.value.toLowerCase().includes(value.toLowerCase()),
    )
    setSimilarResults(filtered)
  }

  const addTag = (tag) => {
    currentValue.current.value = ''
    setSimilarResults(tags)

    currentTags.push({
      id: tag.id,
      tag: tag.value,
    })
    setCurrentTags([...currentTags])
  }

  useEffect(() => {
    if (!showSimilar) return []
    const handleClickOutside = (event) => {
      if (!tagsRef.current.contains(event.target)) {
        setSimilarResults([])
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [showSimilar])

  return (
    <div className="tags-full-container" ref={tagsRef}>
      <div className="input-tags-container">
        <Tags showTags={currentTags} isClosable setTags={setCurrentTags} />
        <input
          ref={currentValue}
          name="tags"
          type="text"
          onKeyDown={handleKeyDown}
          onChange={onChange}
        />
      </div>
      <div className="list-container">
        {showSimilar ? similarResults.map((result) => (
          <Button classButton="tags-result" onClick={() => addTag(result)} text={result.value} key={result.id} />
        )) : ''}
      </div>
      <TagsSuggestions onTagClick={addTag} />
    </div>
  )
}

TagsInput.propTypes = {
  showSimilar: PropTypes.bool,
}

TagsInput.defaultProps = {
  showSimilar: false,
}

export default TagsInput
