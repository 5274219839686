import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { forgotPasswordRequest } from '../../services/ForgotPassword/ForgotPasswordHandler.ts'
import PasswordFlowHeader from '../Templates/PasswordHeader/PasswordFlowHeader'
import '../login/loginStyles.scss'
import InputComponent from '../Templates/Input/InputComponent'
import LoginFlowTitles from '../Templates/FlowTitles/LoginFlowTitles'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isOK, setIsOK] = useState(true)

  const handleForgotRequest = async () => {
    const [statusCode] = await forgotPasswordRequest(email.trim())

    if (statusCode === 200) {
      navigate('/verification', {
        state: {
          mail: email,
        },
      })
    } else {
      setIsOK(false)
    }
  }

  const handleEmail = (value) => setEmail(value)
  return (
    <div className="screen-wrapper">
      <div className="form-wrapper">
        <PasswordFlowHeader route="" />
        <div className="form-body form-password">
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Forgot Password?" spanClass="form-body-title" />
          <LoginFlowTitles classSelected="reset-wrapper span-wrapper" title="Email" />
          <InputComponent valueToHandle={email} setValueToHandle={handleEmail} isLoginOk={isOK} />
          {
            (isOK)
              ? ''
              : <LoginFlowTitles classSelected="span-wrapper" title="Invalid email" spanClass="forgot-control forgot-control-error" />
          }
          <input type="button" className="form-btn forgot-pass-btn" value="Send" onClick={() => handleForgotRequest()} />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
