import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { loggedUser } from '../../../UserContext/UserReducer'
import Button from '../../button/Button'
import edit from '../../../../assets/images/edit.svg'
import activeJob from '../../../../assets/images/Toggle.svg'
import unActiveJob from '../../../../assets/images/unToggle.svg'
import readMore from '../../../../assets/images/readMore.svg'
import addIcon from '../../../../assets/images/addBtn.svg'

import './jobCard.scss'
import { disableJobOpening } from '../../../services/JobOpenings/jobOpenings.service.ts'
import BlurScreen from '../../Templates/BlurScreen/BlurScreen'
import ReadMoreForm from '../../ReadMoreForm/ReadMoreForm'

const JobCard = ({
  processesTotal,
  setSelectedJob,
  applicant,
  job,
}) => {
  const [isJobActive, setIsJobActive] = useState(job.status !== 0)
  const userState = useSelector(loggedUser)
  const navigate = useNavigate()
  const [showBlurScreen, setShowBlurScreen] = useState(false)

  const handleViewMoreForm = () => {
    setSelectedJob(job)
    setShowBlurScreen(true)
  }

  const handleSelectedJob = () => {
    setSelectedJob(job)
    navigate('/recruiter/form', {
      state: {
        processesTotal,
        applicant,
        newIntervieew: true,
      },
    })
  }

  const handleEditedJob = () => {
    setSelectedJob(job)
    navigate('/admin/editJob')
  }

  const handleDisableJob = async () => {
    const [statusCode, response] = await disableJobOpening(userState.authToken, job.id)
    if (statusCode === 200 && response) setIsJobActive(!isJobActive)
  }
  return (
    <>
      <div className={`${isJobActive ? 'card-wrapper' : 'card-wrapper card-wrapper-inactive'}`}>
        <div
          className="card-content"
        >
          <div className="card-left-wrapper">
            <div className="card-wrapper-header">
              <h2>
                {job.name}
                {' '}
                -
                {' '}
                {job.companyName}
              </h2>
              {
                userState.userGroup.includes('Admin') ? (
                  <div className="icons-container">
                    <Button
                      icon={[edit]}
                      onClick={handleEditedJob}
                    />
                    <Button
                      icon={(!isJobActive)
                        ? [activeJob] : [unActiveJob]}
                      onClick={() => handleDisableJob()}
                    />
                  </div>
                ) : ''
              }
              <div className="procesess">
                <span>Total Processes:</span>
                <span>{`${processesTotal}`}</span>
              </div>
            </div>
            <button
              className="card-wrapper-body"
              type="button"
              onClick={handleSelectedJob}
              disabled={!isJobActive}
            >
              <span>{`Rate: $${job.rateMin} - $${job.rateMax}/hour`}</span>
              <span>{`English required: ${job.englishRequired}`}</span>
              <span>{`Description: ${job.description}`}</span>
            </button>
          </div>
          <div className="card-wrapper-footer">
            <Button
              icon={[addIcon]}
              classButton="processes-btn"
              text="Add&#13;&#10;Interview"
              onClick={handleSelectedJob}
              disabled={!isJobActive}
            />
          </div>
        </div>

        <Button
          icon={[readMore]}
          text="read more"
          classButton="read-more-btn"
          onClick={() => handleViewMoreForm()}
        />
      </div>
      {
      showBlurScreen
        ? (
          <BlurScreen>
            <ReadMoreForm onClose={() => setShowBlurScreen(false)} />
          </BlurScreen>
        )
        : ''
    }
    </>
  )
}

JobCard.propTypes = {
  applicant: PropTypes.shape({
    id: PropTypes.number,
  }),
  job: PropTypes.shape({
    form: PropTypes.shape({
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ),
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    companyName: PropTypes.string,
    rateMin: PropTypes.number,
    rateMax: PropTypes.number,
    description: PropTypes.string,
    countryName: PropTypes.string,
    englishRequired: PropTypes.string,
    limitDate: PropTypes.string,
    createdBy: PropTypes.string,
    expectedRegionProfile: PropTypes.string,
    status: PropTypes.number,
  }).isRequired,
  processesTotal: PropTypes.number,
  setSelectedJob: PropTypes.func,
}

JobCard.defaultProps = {
  processesTotal: '',
  setSelectedJob: () => {},
  applicant: undefined,
}

export default JobCard
