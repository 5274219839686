import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  label, value, checked, handleItemChange,
  isNeeded, disabled, checkedChange, uniqueID,
  required,
}) => {
  const [checkedItem, setCheckedItem] = useState(false)

  useEffect(() => {
    setCheckedItem(checkedChange)
  }, [checkedChange])

  return (
    <label htmlFor={uniqueID || `${label}-${value}`} className="checkbox-container">
      {
      checked !== undefined
        ? (
          <>
            <input
              defaultChecked={checked}
              className="checkbox-input"
              type="checkbox"
              id={uniqueID || `${label}-${value}`}
              value={value}
              onChange={handleItemChange}
              disabled={disabled}
              required={required}
            />
            <span className="checkbox-text" />
          </>
        )
        : (
          <>
            <input
              checked={checkedItem}
              className="checkbox-input"
              type="checkbox"
              id={`${label}-${value}`}
              value={value}
              onChange={handleItemChange}
              disabled={disabled}
              required={required}
            />
            <span className="checkbox-text" />
          </>
        )
      }
      <p>{label}</p>
      { isNeeded
        ? <span className="neeeded-attr">*  </span> : ''}
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  isNeeded: PropTypes.bool,
  handleItemChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  checkedChange: PropTypes.bool,
  uniqueID: PropTypes.string,
  required: PropTypes.bool,
}

Checkbox.defaultProps = {
  isNeeded: false,
  value: '',
  checked: undefined,
  disabled: false,
  checkedChange: false,
  uniqueID: undefined,
  required: false,
}

export default Checkbox
