import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Templates/FormsElement/Checkbox'
import './filters.scss'
import { returnCandidateContext } from '../Contexts/CandidatesContext'
import Button from '../button/Button'
import close from '../../../assets/images/close.svg'

const Filters = ({
  setSelectedTags,
  selectedFilters,
  closeFilters,
}) => {
  const [active, setActive] = useState(0)
  const {
    filtersJson,
  } = returnCandidateContext()
  const filtersRef = useRef()
  const [filtersSearchResults, setFiltersSearchResutl] = useState(filtersJson)

  useEffect(() => {
    /**
     * TODO: Fetch selected filters
     */
    setFiltersSearchResutl([...filtersJson])
  }, [selectedFilters])

  useEffect(() => {
    if (!closeFilters) return []
    const handleClickOutside = (event) => {
      if (!filtersRef.current.contains(event.target)) {
        closeFilters()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [closeFilters])

  const openItem = (index) => {
    // Expand filter item
    if (active === index) {
      setActive(-1)
    } else {
      setActive(index)
    }
  }

  const closeItem = (index, event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      openItem(index)
    }
  }

  const handleCheckboxChange = (e) => {
    const list = [...selectedFilters]
    const { value, checked } = e.target
    const parsedObject = JSON.parse(value)

    if (checked) {
      list.push(parsedObject)
    } else {
      const index = list.findIndex((item) => item.value === parsedObject.value)
      if (index !== -1) {
        list.splice(index, 1)
      }
    }

    setSelectedTags(list)
  }

  return (
    <div className="filters" ref={filtersRef}>
      {
        closeFilters ? <Button classButton="close-filters" icon={[close]} onClick={() => closeFilters()} /> : ''
      }
      <div className="filter-list">
        {
          filtersSearchResults
            && filtersSearchResults.map((item, index) => (
              <div
                key={item.id}
                className={`${index === active ? 'item-active' : 'item'}`}
                type="button"
              >
                <button
                  className="filter-head"
                  onClick={() => openItem(index)}
                  onKeyPress={(e) => closeItem(index, e)}
                  type="button"
                >
                  <h1>
                    {item.value}
                  </h1>
                  <span>{`${index === active ? '-' : '+'}`}</span>
                </button>
                <div className="information">
                  {
                  item.possible.map((possibleFilter) => (
                    <Checkbox
                      key={possibleFilter.id}
                      value={`{ "id": "${item.id}", "value": "${possibleFilter.id}", "tag": "${possibleFilter.value}" }`}
                      label={possibleFilter.value}
                      handleItemChange={handleCheckboxChange}
                      checkedChange={selectedFilters.findIndex(
                        (search) => search.id === item.id
                         && search.value === possibleFilter.id.toString(),
                      )
                        !== -1}
                    />
                  ))
                }
                </div>
              </div>
            ))
        }
      </div>
    </div>
  )
}

Filters.propTypes = {
  setSelectedTags: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    tag: PropTypes.string,
  })),
  closeFilters: PropTypes.func,
}

Filters.defaultProps = {
  selectedFilters: [],
  closeFilters: undefined,
}

export default Filters
