/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../button/Button'
import { returnCreateJobContext } from '../Contexts/CreateJobContext'
import close from '../../../assets/images/close.svg'
import back from '../../../assets/images/createField/back.png'
import line from '../../../assets/images/createField/line.png'
import rectangle from '../../../assets/images/createField/rectangle.png'
import remove from '../../../assets/images/createField/remove.png'
import './popup.scss'

const PopUpEditInput = ({
  type, childId, parentId, closePopup, defaultValue,
}) => {
  const placeholder = 'Write the question to ask...'
  const optionsPlaceholder = 'Default'

  const { setFullSections, fullSections } = returnCreateJobContext()
  const [renderOptions, setRenderOptions] = useState(false)
  const [options, setOptions] = useState()
  const popupRef = useRef()

  const sectionToEdit = fullSections.find((item) => item.id === parentId).questions
  const editingInput = sectionToEdit.find((item) => item.id === childId)

  useEffect(() => {
    if (editingInput.isNewQuestion) {
      for (let i = 0; i < editingInput.options.length; i += 1) {
        const option = editingInput.options[i]
        if (option.value === undefined) {
          editingInput.options[i] = {
            id: `Existing-${i}`,
            value: option,
          }
        }
      }
    }
    setOptions(editingInput.options)
  }, [])

  const handleInputChange = (e, index) => {
    const { value } = e.target

    if (renderOptions) {
      if (value === '') {
        options[index].value = optionsPlaceholder
      } else {
        options[index].value = value
      }
    } else {
      editingInput.label = value
    }
    setFullSections([...fullSections])
  }

  const handleMinDate = (e) => {
    const { value } = e.target

    editingInput.placeholder = value.replace('/', '-')
    setFullSections([...fullSections])
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target

    editingInput.isMandatory = checked ? 1 : 0
    setFullSections([...fullSections])
  }

  const handleAddOption = () => {
    options.push({
      id: `${options.at(-1).id + 1}`,
      value: optionsPlaceholder,
    })
    editingInput.options = options
    setOptions([...options])
  }

  const handleRemoveOption = (index) => {
    if (options.length !== 1) {
      options.splice(index, 1)
    }
    editingInput.options = options
    setOptions([...options])
    setFullSections([...fullSections])
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef])

  return (
    <div className="pop-up" ref={popupRef}>
      <div className="top-pop-up" />
      {
      renderOptions
        ? (
          <>
            <div className="pop-up-header">
              <h1>Manage options</h1>
              <Button icon={[back]} classButton="back-button" onClick={() => setRenderOptions(false)} />
            </div>

            {
             options.map((item, index) => (
               <div className="popup-row popup-row-option" key={`${item.id}`}>
                 <img src={rectangle} alt="option" className="option-icon" />
                 <input
                   type="text"
                   placeholder={item.value === optionsPlaceholder ? optionsPlaceholder : undefined}
                   defaultValue={item.value !== optionsPlaceholder ? item.value : undefined}
                   onChange={(e) => handleInputChange(e, index)}
                 />
                 <Button
                   classButton="remove-icon"
                   icon={[remove]}
                   iconAlt="remove-icon"
                   onClick={() => handleRemoveOption(index)}
                 />
               </div>
             ))
           }
            <div className="popup-row">
              <Button classButton="secondary-button" text="Add new option" onClick={() => handleAddOption()} />
            </div>
          </>
        )
        : (
          <>
            <div className="pop-up-header">
              <h1>Options</h1>
              <Button icon={[close]} onClick={() => closePopup()} />
            </div>
            <div className="popup-row">
              <div className="section-title-container">
                <img src={line} alt="option" className="option-icon" />
                <h2>Field title</h2>
              </div>
              <input
                type="text"
                defaultValue={defaultValue !== placeholder ? defaultValue : undefined}
                placeholder={placeholder}
                onChange={handleInputChange}
              />
            </div>
            <div className="popup-row">
              <div className="section-title-container">
                <img src={line} alt="option" className="option-icon" />
                <h2>General settings</h2>
              </div>
              <div className="popup-row-text">
                <input id="checkboxRequired" type="checkbox" onChange={handleCheckboxChange} />
                <label htmlFor="checkboxRequired">Required</label>
              </div>
            </div>
            {
              type === 'date'

                ? (
                  <div className="popup-row">
                    <div className="section-title-container">
                      <img src={line} alt="option" className="option-icon" />
                      <h2>Set min date</h2>
                    </div>
                    <div className="popup-row-text">
                      <input type="date" onChange={handleMinDate} />
                    </div>
                  </div>
                )
                : ''
            }
            {
              type === 'selector' || type === 'radio' || type === 'checkbox'
                ? <Button classButton="secondary-button" text="Manage Options" onClick={() => setRenderOptions(true)} />
                : ''
            }
          </>
        )
      }
    </div>
  )
}

PopUpEditInput.propTypes = {
  type: PropTypes.string,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  childId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  closePopup: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
}

PopUpEditInput.defaultProps = {
  type: 'string',
  defaultValue: undefined,
}

export default PopUpEditInput
