import React from 'react'
import './splashScreen.scss'

const Spinner = () => (
  <div className="splash-screen">
    <div className="spinner" />
  </div>
)

export default Spinner
