import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import './kanBanElement.scss'
import userIcon from '../../../../assets/images/userIcon.svg'
import BlurScreen from '../BlurScreen/BlurScreen'
import generalTemplate from '../../../../assets/jsons/generalForm.json'
import RenderForm from '../RenderForm/RenderForm'

const KanBanColumnElement = ({
  applicant,
  aplicantColumn,
  randomColor,
  blockDrag,
}) => {
  const applicantData = applicant

  const [showComplete, setShowComplete] = useState(false)
  const [generalForm, setGeneralForm] = useState(cloneDeep(generalTemplate))

  useEffect(() => {
    const jobOpeningAnswers = applicantData.jobOpening
    if (jobOpeningAnswers) {
      generalForm[0].questions[0].options = [jobOpeningAnswers.clientName]
      generalForm[0].questions[1].answer = jobOpeningAnswers.name
      generalForm[0].questions[2].answer = jobOpeningAnswers.countryName
      generalForm[0].questions[3].options = [jobOpeningAnswers.companyName]
      generalForm[0].questions[4].options = [jobOpeningAnswers.typeName]
      generalForm[0].questions[5].answer = jobOpeningAnswers.internalCode
      generalForm[0].questions[6].answer = jobOpeningAnswers.rateMin
      generalForm[0].questions[7].answer = jobOpeningAnswers.rateMax
      generalForm[0].questions[8].answer = jobOpeningAnswers.jobDescriptorPath

      generalForm[0].questions[9].answer = new Date(
        jobOpeningAnswers.openDate,
      ).toDateString()
      generalForm[0].questions[10].answer = new Date(
        jobOpeningAnswers.limitDate,
      ).toDateString()

      generalForm[0].questions[11].answer = jobOpeningAnswers.expectedRegionProfile
      generalForm[0].questions[12].answer = jobOpeningAnswers.description
      generalForm[0].questions[13].answer = jobOpeningAnswers.englishRequired

      setGeneralForm(generalForm)
    }

    if (applicantData?.jobOpening?.form?.sections[1]) {
      for (let i = 1; i < applicantData?.jobOpening?.form?.sections.length; i += 1) {
        applicantData.jobOpening.form.sections[i].showInRow = true
      }
    }
  }, [])

  const [, drag] = useDrag(() => ({
    type: 'CARD',
    item: blockDrag ? applicantData : { ...applicantData, column: aplicantColumn },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <>
      <button
        ref={drag}
        type="button"
        onClick={() => setShowComplete(!showComplete)}
        className="kanban-element-wrapper"
      >
        <div className={`kanban-element-header ${randomColor.header}`}>
          <div className="kanban-header-description">
            <span>{applicantData.jobOpening?.name || 'No Job Opening'}</span>
          </div>
          <img src={userIcon} alt="userIcon" />
        </div>
        <div className={`kanban-body-header ${randomColor.body}`}>
          {
            applicantData.applicant.section
              ? (
                <span>
                  {applicantData.applicant.section.questions[0].answer[0]}
                  {' '}
                  {applicantData.applicant.section.questions[1].answer[0]}
                </span>
              )
              : (
                <span>
                  {applicantData.jobOpening.form.sections[0].questions[0].answer[0]}
                  {' '}
                  {applicantData.jobOpening.form.sections[0].questions[1].answer[0]}
                </span>
              )
          }
        </div>
      </button>

      {
      showComplete && applicantData.jobOpening
        ? (
          <BlurScreen>
            <RenderForm
              candidateInfo={applicantData}
              showSectionAsText={Array(
                (applicantData.jobOpening?.form?.sections?.length || 0) + 1,
              )
                .fill(null).map((_, i) => i)}
              isNewJobOpening={false}
              sections={[...generalForm, ...applicantData?.jobOpening?.form?.sections || []]}
              closeFunction={() => setShowComplete(false)}
              showAllAsText
              showTags
              showFeedback
            />
          </BlurScreen>
        ) : ''
      }
      {
      showComplete && !applicantData.jobOpening
        ? (
          <BlurScreen>
            <RenderForm
              candidateInfo={applicantData}
              showSectionAsText={[0]}
              isNewJobOpening={false}
              sections={[applicantData.applicant.section] || []}
              closeFunction={() => setShowComplete(false)}
              showAllAsText
              showTags
              showFeedback
            />
          </BlurScreen>
        ) : ''
      }
    </>
  )
}

KanBanColumnElement.propTypes = {
  aplicantColumn: PropTypes.string.isRequired,
  applicant: PropTypes.shape({
    jobOpeningName: PropTypes.string,
    applicant: PropTypes.shape({
      name: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      technicalScore: PropTypes.string,
      rate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      englishLevel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      cv: PropTypes.string,
      recruiter: PropTypes.string,
    }),
    responsible: PropTypes.shape({
      name: PropTypes.string,
      lastname: PropTypes.string,
    }),
    jobOpening: PropTypes.shape({
      clientName: PropTypes.string,
      name: PropTypes.string,
      requirements: PropTypes.string,
      rateMin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      rateMax: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      countryName: PropTypes.string,
      description: PropTypes.string,
      englishRequired: PropTypes.string,
      openDate: PropTypes.string,
      limitDate: PropTypes.string,
      expectedRegionProfile: PropTypes.string,
      jobDescriptorPath: PropTypes.string,
      internalCode: PropTypes.string,
      form: PropTypes.shape({
        id: PropTypes.number,
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            showInRow: PropTypes.bool,
          }),
        ),
      }),
    }),
  }),
  randomColor: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }),
  blockDrag: PropTypes.bool,
}

KanBanColumnElement.defaultProps = {
  applicant: undefined,
  randomColor: undefined,
  blockDrag: false,
}

/**
 * Checks if the applicant card needs a render
 * @param {Object} oldState previous props of component
 * @param {Object} newState new props of component
 * @returns (
 *  true -> doesn't renders component
 *  false -> renders the component
 * )
 */
const checkCanRender = (oldState, newState) => (
  oldState.applicantData === newState.applicantData
  && oldState.toggleStyle === newState.toggleStyle
)
const MemoColumnElement = React.memo(KanBanColumnElement, checkCanRender)

export default MemoColumnElement
