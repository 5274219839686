/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/prefer-default-export */
import React, {
  createContext, useContext, useReducer,
} from 'react'
import {
  fetchCandidates, disableCandidate, enableCandidate,
  updateCandidate, createCandidate, getCandidateModel,
} from '../../services/Candidates/candidates.service.ts'
import { fetchCountries } from '../../services/Countries/countries.service.ts'
import { fetchPaymentStatus } from '../../services/Payment_Status/payment.status.service.ts'
import { fetchClients } from '../../services/clients/clients.service.ts'
import { fetchFeedbackTypes } from '../../services/Feedback/feedback.service.ts'
import { fetchCompanies } from '../../services/Companies/companies.service.ts'
import { fetchJobOpeningTypes } from '../../services/JobOpenings/jobOpenings.service.ts'

const ACTIONS = {
  UPDATE: 'update',
  RESET: 'reset',
}

const initVals = {
  candidatesJson: undefined,
  countriesJson: undefined,
  paymentStatusJson: undefined,
  selectedCandidate: 0,
  currenciesJson: undefined,
  skillsJson: undefined,
  clientsJson: undefined,
  processJobsStatusJson: undefined,
  selectedJob: undefined,
  feedbackJson: undefined,
  companiesJson: undefined,
  jobTypesJson: undefined,
  filtersJson: undefined,
  jobsJson: undefined,
}

const reduce = (state, event) => {
  switch (event.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        ...event.payload,
      }
    case ACTIONS.RESET:
      return initVals
    default:
      return state
  }
}

const CandidateContext = createContext({
  ...initVals,
})

export const FetchCandidatesInfo = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initVals)

  const setSelectedJob = (job) => {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        selectedJob: job,
      },
    })
  }

  const setJobsJson = (jobs) => {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        jobsJson: jobs,
      },
    })
  }

  /**
   * get all applicant
   * @param {*} authToken user auth token
   */
  const getCandidatesList = async (authToken, query = '') => {
    // Fetching all applicant with a process job opening
    const [statusCode, response] = await fetchCandidates(authToken, query)
    if (statusCode === 200) {
      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          candidatesJson: response,
        },
      })
    }
    return [statusCode, response]
  }
  /**
   * disable applicant
   * @param {*} authToken user auth token
   * @param {*} id candidate id
   */
  const disableCandidateByID = async (authToken, id) => {
    // disables applicant user by ID
    const [statusCode, response] = await disableCandidate(authToken, id)
    if (statusCode === 200) {
      // eslint-disable-next-line no-console
      console.log(response)
    }
  }
  /**
   * enable job opening
   * @param {*} authToken user auth token
   * @param {*} id candidate id
   */
  const enableCandidateByID = async (authToken, id) => {
    // enables applicant user by ID
    const [statusCode, response] = await enableCandidate(authToken, id)
    if (statusCode === 200) {
      // eslint-disable-next-line no-console
      console.log(response)
    }
  }
  /**
   * update candidate
   * @param {*} candidateData new candidate data (objetct)
   * @param {*} authToken user auth token
   */
  const updateCandidateByID = async (candidateData, authToken) => {
    // update applicant user by ID
    const [statusCode, response] = await updateCandidate(candidateData, authToken)
    if (statusCode === 200) {
      // eslint-disable-next-line no-console
      console.log(response)
    }
  }
  /**
   * new candidate
   * @param {*} candidateData new candidate data (objetct)
   * @param {*} authToken user auth token
   */
  const createNewCandidate = async (candidateData, authToken) => {
    // creates new applicant user
    const [statusCode, response] = await createCandidate(candidateData, authToken)
    if (statusCode === 200 && response) {
      getCandidatesList(authToken)
    }
    return [statusCode, response]
  }

  /**
   * get all countries
   * @param {*} authToken user auth token
   */
  const getCountries = async (authToken) => {
    // Fetching all countries
    const [statusCode, response] = await fetchCountries(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }
  /**
   * get all payments status
   * @param {*} authToken user auth token
   */
  const getPaymentStatus = async (authToken) => {
    // Fetching fall payments status
    const [statusCode, response] = await fetchPaymentStatus(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }
  /**
   * get all clients
   * @param {*} authToken user auth token
   */
  const getClients = async (authToken) => {
    // Fetching all clients
    const [statusCode, response] = await fetchClients(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }
  /**
   * get all job types
   * @param {*} authToken user auth token
   */
  const getJobTypes = async (authToken) => {
    // Fetching all clients
    const [statusCode, response] = await fetchJobOpeningTypes(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }
  /**
   * get all companies
   * @param {*} authToken user auth token
   */
  const getCompanies = async (authToken) => {
    // Fetching all clients
    const [statusCode, response] = await fetchCompanies(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }
  /**
   * get candidate template model
   * @param {*} authToken user auth token
   */
  const getCandidateTemplate = async (authToken) => {
    // Fetching all clients
    const [statusCode, response] = await getCandidateModel(authToken)
    if (statusCode === 200) {
      return [response]
    } return []
  }
  /**
   * get all feedback types
   * @param {*} authToken user auth token
   */
  const getFeedbackTypes = async (authToken) => {
    // Fetching all countries
    const [statusCode, response] = await fetchFeedbackTypes(authToken)
    if (statusCode === 200) {
      return response
    } return []
  }

  /**
   * Function to fetch all the data that will stay constant on user session
   */
  const getInitialData = async (authToken) => {
    await Promise.all([
      getFeedbackTypes(authToken),
      getClients(authToken),
      getPaymentStatus(authToken),
      getCountries(authToken),
      getCompanies(authToken),
      getJobTypes(authToken),
      getCandidateTemplate(authToken),
    ]).then((responses) => {
      const [
        feedbackJson,
        clientsJson,
        paymentStatusJson,
        countriesJson,
        companiesJson,
        jobTypesJson,
        candidateTemplateModel,
      ] = responses

      const countriesLabel = candidateTemplateModel[0].questions.find((item) => item.label === 'Country')
      if (countriesLabel) {
        countriesLabel.options = countriesJson
      }

      const filtersJson = [
        {
          id: 'companyID',
          value: 'Company',
          possible: companiesJson,
        },
        {
          id: 'clientID',
          value: 'Client',
          possible: clientsJson,
        },
      ]

      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          feedbackJson,
          clientsJson,
          paymentStatusJson,
          countriesJson,
          companiesJson,
          jobTypesJson,
          filtersJson,
          candidateTemplateModel,
        },
      })
    })
  }

  return (
    <CandidateContext.Provider value={{
      ...state,
      getInitialData,
      setSelectedJob,
      getCandidatesList,
      disableCandidateByID,
      enableCandidateByID,
      updateCandidateByID,
      createNewCandidate,
      setJobsJson,
    }}
    >
      { children }
    </CandidateContext.Provider>
  )
}

export const returnCandidateContext = () => {
  const context = useContext(CandidateContext)
  if (!context) return 'There is not context'
  return context
}
