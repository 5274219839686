import React from 'react'
import logo from '../../../assets/images/logoWhite.png'
import './splashScreen.scss'

const SplashScreen = () => (
  <div className="splash-screen">
    <img src={logo} alt="wotdev-logo" />
  </div>
)

export default SplashScreen
