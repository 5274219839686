import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import indicator from '../../../assets/images/polygon.svg'
import './button.scss'

const Button = ({
  iconAlt,
  icon,
  text,
  buttonType,
  onClick,
  classButton,
  iconClass,
  title,
  requiresIndicator,
  disabled,
}) => {
  const [className, setClassName] = useState('')
  const iconType = className.includes('selected') || disabled ? icon[1] : icon[0]
  const location = useLocation()
  const navigate = useNavigate()

  const buttonBehaviour = () => {
    if (buttonType === 'logo') {
      navigate('/')
    } else if (onClick) {
      onClick()
    } else {
      navigate(`/${text.toLowerCase().replace(/\s/g, '')}`)
    }
  }

  if (buttonType === 'sidebar') {
    useEffect(() => {
      if (location.pathname.toLowerCase().includes(text?.replace(' ', '/').toLowerCase()) && buttonType === 'sub-mneu') {
        setClassName('active-background')
      } else if (location.pathname.toLowerCase().includes(text?.toLowerCase()) && buttonType === 'sidebar') {
        setClassName('active')
      } else if (location.pathname.toLowerCase().includes(text?.toLowerCase()) && buttonType === 'tabRedirect') {
        setClassName('responsive-active')
      } else {
        setClassName('')
      }
    }, [location.pathname])
  }

  return (
    <button
      title={title}
      type="button"
      className={`${classButton} ${className} ${requiresIndicator ? 'requires-btn' : ''}`}
      onClick={() => buttonBehaviour()}
      disabled={disabled}
    >
      { icon.length > 0
        ? (
          <img
            className={iconClass}
            alt={iconAlt}
            src={iconType}
          />
        ) : '' }

      { text && !requiresIndicator ? <span>{text}</span> : ''}

      {requiresIndicator ? (
        <div className="indicator-hover">
          <img
            style={{ border: 'none' }}
            className={`${iconClass} indicator-icon-hover`}
            alt={iconAlt}
            src={indicator}
          />
          <p className="indicator-text">
            {text}
          </p>
        </div>
      ) : ''}
    </button>
  )
}

Button.propTypes = {
  iconAlt: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  classButton: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  requiresIndicator: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  iconAlt: undefined,
  icon: [],
  text: undefined,
  buttonType: undefined,
  onClick: undefined,
  classButton: '',
  iconClass: '',
  title: undefined,
  requiresIndicator: false,
  disabled: false,
}

export default Button
