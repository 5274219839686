import React from 'react'
import PropTypes from 'prop-types'
import './formTitle.scss'
import Button from '../../button/Button'
import edit from '../../../../assets/images/edit.svg'

const FormTitle = ({ divClassName, title, onEdit }) => (
  <div className={`form-title-wrapper ${divClassName}`}>
    <h2>{title}</h2>
    { onEdit ? <Button onClick={() => onEdit()} icon={[edit]} /> : '' }
  </div>
)

FormTitle.propTypes = {
  divClassName: PropTypes.string,
  title: PropTypes.string,
  onEdit: PropTypes.func,
}
FormTitle.defaultProps = {
  divClassName: '',
  title: '',
  onEdit: undefined,
}

export default FormTitle
