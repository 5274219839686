import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../button/Button'
import feedbackIcon from '../../../assets/images/feedback.png'
import disabledFeedbackIcon from '../../../assets/images/feedback-disabled.png'
import './Feedback.scss'
import Selector from '../Templates/Selector/Selector'
import { returnCandidateContext } from '../Contexts/CandidatesContext'

const FeedbackInput = ({
  item,
  selectedType,
  handleSpecificFeedback,
  handleGeneralFeedback,
  handleTestFeedback,
}) => {
  const currentValue = useRef()
  const scoreValue = useRef()
  const { feedbackJson } = returnCandidateContext()
  let testID = feedbackJson[0].id

  const [score, setScore] = useState()

  const handleTypeChange = (value) => {
    testID = value
  }

  const onSendFeedback = () => {
    if (currentValue.current.value !== '' && item.type === 'specific') {
      handleSpecificFeedback(currentValue.current.value)
      currentValue.current.value = ''
    } else if (currentValue.current.value !== '' && item.type === 'general') {
      handleGeneralFeedback(currentValue.current.value)
      currentValue.current.value = ''
    } else if (currentValue.current.value !== '' && item.type === 'test') {
      handleTestFeedback(
        currentValue.current.value,
        scoreValue.current.value,
        {
          testID,
          testName: feedbackJson.find((test) => test.id === testID).value,
        },
      )
      currentValue.current.value = ''
      scoreValue.current.value = ''
    }
  }

  const enforceMinMax = () => {
    if (parseInt(scoreValue.current.value, 10) < parseInt(scoreValue.current.min, 10)) {
      scoreValue.current.value = scoreValue.current.min
    }
    if (parseInt(scoreValue.current.value, 10) > parseInt(scoreValue.current.max, 10)) {
      scoreValue.current.value = scoreValue.current.max
    }
    setScore(scoreValue.current.value)
  }

  return (
    <>
      {
        item.type === 'test'
          ? (
            <div className="test-header">
              <Selector
                label="Test Type"
                items={feedbackJson}
                handleChange={handleTypeChange}
                extraClass="test-selector"
                disabled={selectedType !== item.id}
              />
              <div className="score-input-container">
                <label htmlFor={item.type}>
                  Score
                </label>
                <input
                  ref={scoreValue}
                  disabled={selectedType !== item.id}
                  name="score"
                  type="number"
                  min="0"
                  max="100"
                  value={score}
                  onBlur={enforceMinMax}
                />
                <label htmlFor={item.type}>/100</label>
              </div>
            </div>
          ) : ''
      }
      {
        item.type !== 'none'
          ? (
            <div className="input-container">
              <textarea
                ref={currentValue}
                disabled={selectedType !== item.id}
                name="feedback"
                type="text"
                placeholder="Add comment..."
              />
              <Button
                disabled={selectedType !== item.id}
                onClick={onSendFeedback}
                icon={[feedbackIcon, disabledFeedbackIcon]}
              />
            </div>
          )
          : ''
      }
    </>
  )
}

FeedbackInput.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  selectedType: PropTypes.number.isRequired,
  handleSpecificFeedback: PropTypes.func.isRequired,
  handleGeneralFeedback: PropTypes.func.isRequired,
  handleTestFeedback: PropTypes.func.isRequired,
}

export default FeedbackInput
