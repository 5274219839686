/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import plus from '../../../../assets/images/plus.png'
import remove from '../../../../assets/images/createField/remove.png'
import './input.scss'
import Button from '../../button/Button'
import Checkbox from './Checkbox'
import PopUpEditInput from '../../popup/PopUp'
import 'react-phone-input-2/lib/style.css'

const Input = ({
  label, placeholder, defaultValue,
  showMiniature, miniatureIAR,
  showAdd, showRemove, type, inputName,
  initialItemList, validateChecked,
  setChanges, position, isEditing, className,
  isNeeded, minValue, disabled, isSelectable,
  parentId, openPopUp, checkedItems, handleRemoveItem,
  setChangesByIndex, changesByIndex,
  renderInitialItemList,
}) => {
  const [itemList, setItemList] = useState(initialItemList || [{ value: '' }])

  useEffect(() => {
    if (renderInitialItemList >= 0) setItemList(initialItemList)
  }, [renderInitialItemList])

  useEffect(() => {
    try {
      if (type === 'radio' && initialItemList) {
        const list = [...initialItemList]

        if (setChanges) {
          setChanges(list)
        } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
          setChangesByIndex(changesByIndex, [{
            [inputName]: list[0],
          }], inputName)
        }
      }
    } catch (e) {
      // there is no default answer
    }
  }, [])

  const [showPopUp, setShowPopUp] = useState(false)

  const handleItemChange = (e, index) => {
    const { name, value } = e.target
    const list = [...itemList]
    list[index][name] = value

    if (setChanges) {
      setChanges(list)
    } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
      setItemList(list)
      setChangesByIndex(changesByIndex, [{
        [inputName]: value,
      }], inputName)
    } else {
      setItemList(list)
    }
  }

  const handlePhoneChange = (e, index) => {
    const list = [...itemList]
    list[index][inputName] = e

    if (setChanges) {
      setChanges(list)
    } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
      setItemList(list)
      setChangesByIndex(changesByIndex, [{
        [inputName]: e,
      }], inputName)
    } else {
      setItemList(list)
    }
  }

  const handleServiceAdd = () => {
    setItemList([...itemList, { [inputName]: '' }])
  }

  const handleCheckboxChange = (e, index = -1) => {
    const { value, checked, name } = e.target

    if (isSelectable && parentId) {
      const selectedElements = validateChecked[parentId]

      if (checked) {
        selectedElements.push(inputName.toString())
      } else {
        const valueIndex = selectedElements.indexOf(inputName.toString())
        if (valueIndex !== -1) {
          selectedElements.splice(index, 1)
        }
      }
    } else {
      let valueToUse = value

      try {
        if (!Number.isNaN(parseInt(value, 10))) {
          valueToUse = parseInt(value, 10)
        }
      } catch (error) {
        // use string
      }

      if (index !== -1) {
        if (type === 'radio') {
          if (setChanges) {
            if (checked) {
              const list = [...validateChecked]

              list.push(valueToUse)
              setChanges(list)
            } else {
              const list = [...validateChecked]

              const valueIndex = list.indexOf(valueToUse)
              if (index !== -1) {
                list.splice(valueIndex, 1)
              }
              setChanges(list)
            }
          } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
            setChangesByIndex(changesByIndex, [{
              [name]: valueToUse,
            }], inputName)
          }
        } else if (type === 'checkbox') {
          const list = setChanges ? [...checkedItems] : checkedItems

          if (checked) {
            list.push(valueToUse)
          } else {
            const valueIndex = list.indexOf(valueToUse)
            if (index !== -1) {
              list.splice(valueIndex, 1)
            }
          }

          if (setChanges) {
            setChanges(list)
          } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
            setChangesByIndex(changesByIndex, [{
              [inputName]: list,
            }], inputName)
          }
        }
      }
    }
  }

  return (
    <div
      className={`input-full-container ${className} ${showPopUp ? 'selected' : ''}`}
    >
      { showMiniature ? (
        <img
          src={itemList[0][inputName][miniatureIAR]}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1'
          }}
          className={`iar_${miniatureIAR}`}
          alt="Thumbnail"
        />
      ) : '' }

      {
        type === 'checkbox'
          ? (
            <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
              {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={inputName}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={label}>
                        {showRemove
                        && (
                        <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                        ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }
              <div className="items-checkbox">
                {
                  initialItemList?.map((item, key) => (
                    <Checkbox
                      key={`${item}${key}`}
                      uniqueID={`${inputName}-${item.id || key}-${item.value || item}`}
                      label={item.value !== undefined ? item.value : item}
                      checked={checkedItems.includes(item.id)
                         || checkedItems.includes(item.value)
                        || checkedItems.includes(item)}
                      value={item.id || item}
                      required={key === 0 ? isNeeded : undefined}
                      disabled={disabled}
                      handleItemChange={(e) => handleCheckboxChange(e, key)}
                    />
                  ))
                }
              </div>
            </div>
          )
          : ''
      }

      {
        type === 'text' || type === 'number' || type === 'url'
          ? (
            <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
              {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={inputName}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={label}>
                        {showRemove
                        && (
                        <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                        ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }

              {itemList.map((singleItem, index) => (
                <div
                  className="input-container"
                  style={itemList.length - 1 === index && showAdd ? { } : { flexDirection: 'row' }}
                  key={`${singleItem}-${index}`}
                >
                  {
                    isEditing
                      ? (
                        <input
                          type={type}
                          name={inputName}
                          id={`${label}-${index}`}
                          placeholder={placeholder}
                          onChange={(e) => handleItemChange(e, index)}
                          value={defaultValue || singleItem[inputName]}
                          disabled={disabled}
                          required={isNeeded}
                        />
                      )
                      : <span>{singleItem[inputName]}</span>
                  }
                </div>
              ))}
            </div>
          )
          : ''
      }

      {
        type === 'time' ? (
          <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
            {
            label
              ? (
                isSelectable
                  ? (
                    <div className="normal-checkbox">
                      <Checkbox
                        value={inputName}
                        isNeeded={isNeeded}
                        label={label}
                        handleItemChange={handleCheckboxChange}
                        checked
                      />
                    </div>
                  )
                  : (
                    <label htmlFor={label}>
                      {showRemove
                      && (
                      <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                      ) }
                      {label}
                      { isNeeded
                        ? <span className="neeeded-attr">*  </span> : ''}
                    </label>
                  )
              )
              : ''
            }

            {itemList.map((singleItem, index) => (
              <div
                className="input-container time-container"
                key={`${singleItem}-${index}`}
              >
                {
                  isEditing
                    ? (
                      <div className="time-container">
                        <div className="time-from">
                          <label htmlFor="time-from">From</label>
                          <input
                            type={type}
                            name={inputName}
                            id={`${label}-${index}`}
                            onChange={(e) => handleItemChange(e, index)}
                            value={defaultValue || (
                              inputName.includes('image')
                                ? singleItem[inputName][miniatureIAR] : singleItem[inputName]
                            )}
                            disabled={disabled}
                            required={isNeeded}
                          />
                        </div>
                        <div className="time-to">
                          <label htmlFor="time-to">To</label>
                          <input
                            type={type}
                            name={inputName}
                            id={`${label}-${index}`}
                            onChange={(e) => handleItemChange(e, index)}
                            value={defaultValue || (
                              inputName.includes('image')
                                ? singleItem[inputName][miniatureIAR] : singleItem[inputName]
                            )}
                            disabled={disabled}
                            required={isNeeded}
                          />
                        </div>
                      </div>
                    )
                    : <span>{singleItem[inputName]}</span>
                }
              </div>
            ))}
          </div>
        )
          : ''
      }

      {
        type === 'radio'
          ? (
            <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
              {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={inputName}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={label}>
                        {showRemove
                        && (
                        <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                        ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }
              <div
                className="radio-container"
              >
                {initialItemList.map((singleItem, index) => (
                  <div
                    className="radio-item"
                    key={`${singleItem.id || index}`}
                  >
                    <input
                      type={type}
                      name={inputName}
                      id={`${label}-${index}`}
                      value={singleItem || index}
                      onChange={(e) => handleCheckboxChange(e, index)}
                      defaultChecked={index === position || checkedItems.includes(singleItem)}
                      disabled={disabled}
                      required={index === 0 ? isNeeded : undefined}
                    />
                    <label htmlFor={singleItem.value || singleItem}>
                      {singleItem.value || singleItem}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : ''
      }

      {
        type === 'date'
          ? (
            <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
              {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={inputName}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={label}>
                        {showRemove
                        && (
                        <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                        ) }

                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }

              {initialItemList.map((singleItem, index) => (
                <div
                  className="input-container"
                  style={itemList.length - 1 === index && showAdd ? { } : { flexDirection: 'row' }}
                  key={`${singleItem}-${index}`}
                >
                  {
                    isEditing
                      ? (
                        <input
                          type={type}
                          name={inputName}
                          id={`${label}-${index}`}
                          onChange={(e) => handleItemChange(e, index)}
                          defaultValue={singleItem[inputName]}
                          placeholder={minValue}
                          min={minValue}
                        />
                      )
                      : <span>{singleItem[inputName]}</span>
                  }
                </div>
              ))}
            </div>
          ) : ''
      }

      {
        type === 'phone'
          ? (
            <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
              {
          label
            ? (
              isSelectable
                ? (
                  <div className="normal-checkbox">
                    <Checkbox
                      value={inputName}
                      isNeeded={isNeeded}
                      label={label}
                      handleItemChange={handleCheckboxChange}
                      checked
                    />
                  </div>
                )
                : (
                  <label htmlFor={label}>
                    {showRemove
                    && (
                    <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                    ) }
                    {label}
                    { isNeeded
                      ? <span className="neeeded-attr">*  </span> : ''}
                  </label>
                )
            )
            : ''
          }

              {itemList.map((singleItem, index) => (
                <div
                  className="input-container"
                  style={itemList.length - 1 === index && showAdd ? { } : { flexDirection: 'row' }}
                  key={`${singleItem}-${index}`}
                >
                  {
                isEditing
                  ? (
                    <PhoneInput
                      country="gt"
                      value={defaultValue || singleItem[inputName]}
                      onChange={(e) => handlePhoneChange(e, index)}
                      disabled={disabled}
                      enableSearch
                      disableSearchIcon
                    />
                  )
                  : (
                    <span>
                      +
                      {singleItem[inputName]}
                    </span>
                  )
              }
                </div>
              ))}
            </div>
          ) : ''
      }

      { showAdd && (
        <Button onClick={handleServiceAdd} classButton="add-more" icon={[plus]} iconAlt="add-more" text="Add a Category" />
      )}

      {
        showPopUp
          ? (
            <PopUpEditInput
              defaultValue={label}
              type={type}
              parentId={parentId}
              childId={inputName}
              closePopup={() => setShowPopUp(false)}
            />
          ) : ''
      }

    </div>
  )
}

Input.propTypes = {
  openPopUp: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showMiniature: PropTypes.bool,
  miniatureIAR: PropTypes.string,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  isEditing: PropTypes.bool,
  handleRemoveItem: PropTypes.func,
  validateChecked: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  initialItemList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        value: PropTypes.string,
      }),
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  checkedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  inputName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setChanges: PropTypes.func,
  position: PropTypes.number,
  className: PropTypes.string,
  isNeeded: PropTypes.bool,
  minValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disabled: PropTypes.bool,
  isSelectable: PropTypes.bool,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setChangesByIndex: PropTypes.func,
  changesByIndex: PropTypes.number,
  renderInitialItemList: PropTypes.number,
}

Input.defaultProps = {
  openPopUp: false,
  inputName: '',
  label: '',
  placeholder: '',
  miniatureIAR: '3x2',
  showMiniature: false,
  showAdd: false,
  showRemove: false,
  isEditing: false,
  handleRemoveItem: () => {},
  defaultValue: undefined,
  type: 'text',
  validateChecked: [],
  checkedItems: [],
  initialItemList: undefined,
  setChanges: undefined,
  position: 0,
  className: '',
  isNeeded: false,
  minValue: undefined,
  disabled: false,
  isSelectable: false,
  parentId: undefined,
  setChangesByIndex: undefined,
  changesByIndex: undefined,
  renderInitialItemList: -1,
}

export default Input
