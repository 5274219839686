import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import './Feedback.scss'
import FeedbackInput from './FeedbackInput'
import { loggedUser } from '../../UserContext/UserReducer'
import { createFeedback } from '../../services/Feedback/feedback.service.ts'

const Feedback = ({
  onEnter,
  pastFeedback,
  processID,
  applicantID,
}) => {
  let TYPES = [
    {
      id: -1,
      type: 'none',
      title: 'None',
    },
    {
      id: 0,
      type: 'general',
      title: 'General feedback (applies on candidate)',
    },
    {
      id: 1,
      type: 'specific',
      title: 'Specific feedback (applies on selected interview)',
    },
    {
      id: 2,
      type: 'test',
      title: 'Test related feedback',
    },
  ]

  if (processID === -1) {
    TYPES = [
      {
        id: -1,
        type: 'none',
        title: 'None',
      },
      {
        id: 0,
        type: 'general',
        title: 'General feedback (applies on candidate)',
      },
      {
        id: 2,
        type: 'test',
        title: 'Test related feedback',
      },
    ]
  }

  const userState = useSelector(loggedUser)
  const [actualFeedback, setActualFeedback] = useState(pastFeedback)
  const [selectedType, setSelectedType] = useState(-1)

  const handleGeneralFeedback = (comment) => {
    const generalFeedbackInterface = {
      value: comment,
      type: 'general',
      applicantID,
    }

    actualFeedback.push({
      ...generalFeedbackInterface,
      id: `newcomment-${actualFeedback.length}`,
      createdAt: `${new Date().toLocaleTimeString()}, ${new Date().toLocaleDateString()}`,
      createdBy: userState.name,
    })

    setActualFeedback([...actualFeedback])

    if (onEnter) onEnter(generalFeedbackInterface)
    else createFeedback(userState.authToken, generalFeedbackInterface)
  }

  const handleSpecificFeedback = (comment) => {
    const specificFeedbackInterface = {
      value: comment,
      type: 'specific',
      processID,
    }

    actualFeedback.push({
      ...specificFeedbackInterface,
      id: `newcomment-${actualFeedback.length}`,
      createdAt: `${new Date().toLocaleTimeString()}, ${new Date().toLocaleDateString()}`,
      createdBy: userState.name,
    })

    setActualFeedback([...actualFeedback])

    if (onEnter) onEnter(specificFeedbackInterface)
    else createFeedback(userState.authToken, specificFeedbackInterface)
  }

  const handleTestFeedback = (comment, score, test) => {
    const testFeedbackInterface = {
      value: comment,
      type: 'test',
      applicantID,
      test: {
        typeID: test.testID,
        score,
      },
    }

    actualFeedback.push({
      ...testFeedbackInterface,
      id: `newtest-t${actualFeedback.length}`,
      createdAt: `${new Date().toLocaleTimeString()}, ${new Date().toLocaleDateString()}`,
      createdBy: userState.name,
      test: test.testName,
      score,
    })

    setActualFeedback([...actualFeedback])

    if (onEnter) onEnter(testFeedbackInterface)
    else createFeedback(userState.authToken, testFeedbackInterface)
  }

  const handleRadioChange = (e) => {
    const { value } = e.target

    let valueToUse = value
    try {
      valueToUse = parseInt(value, 10)
    } catch (error) {
      // use string
    }

    setSelectedType(valueToUse)
  }

  return (
    <div className="feedback-container">
      <div className="existing-feedback-container">
        {actualFeedback.map((comment) => (
          <li className="message" key={comment.id}>
            <div className="header">
              <h1>{comment.createdBy}</h1>
              <p>{comment.createdAt}</p>
            </div>
            {
            comment.score
              ? (
                <div className="test-header">
                  <div className="title">
                    <h2>
                      Test:
                    </h2>
                    {comment.test}
                  </div>
                  <div className="score">
                    <h2>
                      Score:
                    </h2>
                    {comment.score}
                    {' '}
                    /100
                  </div>
                </div>
              )
              : ''
          }
            <p>{comment.value}</p>
          </li>
        ))}
      </div>

      <form>
        {
          TYPES.map((item) => (
            <div className={`${item.id === selectedType ? 'active-label' : ''} feedback-input-container`} key={`feedback-${item.id}`}>
              <div className="feedback-header">
                <input
                  type="radio"
                  name="feedback-types"
                  value={item.id}
                  onChange={handleRadioChange}
                  id={item.id}
                  defaultChecked={item.id === selectedType}
                />
                <label htmlFor={item.id}>
                  {item.title}
                </label>
              </div>
              <FeedbackInput
                item={item}
                selectedType={selectedType}
                handleGeneralFeedback={handleGeneralFeedback}
                handleSpecificFeedback={handleSpecificFeedback}
                handleTestFeedback={handleTestFeedback}
              />
            </div>
          ))
        }
      </form>
    </div>
  )
}

Feedback.propTypes = {
  onEnter: PropTypes.func,
  pastFeedback: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      createdBy: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  processID: PropTypes.number,
  applicantID: PropTypes.number,
}

Feedback.defaultProps = {
  onEnter: undefined,
  pastFeedback: [],
  processID: -1,
  applicantID: -1,
}

export default Feedback
