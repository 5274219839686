import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loggedUser } from '../../../UserContext/UserReducer'
import './recruiterMenu.scss'
import JobCard from '../../jobCarousel/JobCard/JobCard'
import FormTitle from '../../Templates/FormsTitle/FormTitle'
import Filters from '../../Filters/Filters'
import Button from '../../button/Button'

import addBtn from '../../../../assets/images/addBtn.svg'
import addBtnHover from '../../../../assets/images/addBtnHover.svg'
import noJob from '../../../../assets/images/jo_not_found.png'
import Tags from '../../Tags/Tags'
import { returnCandidateContext } from '../../Contexts/CandidatesContext'
import { fetchJobOpening } from '../../../services/JobOpenings/jobOpenings.service.ts'

const RecruiterMenu = ({ setIsSpinnerActive }) => {
  const userState = useSelector(loggedUser)
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState([])
  const { state } = useLocation()

  const {
    setJobsJson,
    jobsJson,
    setSelectedJob,
  } = returnCandidateContext()

  useEffect(async () => {
    if (!userState.authToken && !userState.isActive) navigate('/')
    if (!jobsJson) setIsSpinnerActive(true)
  }, [userState])

  useEffect(async () => {
    const queries = selectedFilters.reduce((groups, item) => {
      const group = (groups[item.id] || [])
      group.push(item.value)
      // eslint-disable-next-line no-param-reassign
      groups[item.id] = group
      return groups
    }, {})

    let completeQuery = ''
    Object.keys(queries).forEach((key) => {
      completeQuery += `${key}=${queries[key].join(',')}&`
    })

    await fetchJobOpening(userState.authToken, completeQuery).then((res) => {
      const [status, response] = res
      if (status === 200) {
        setJobsJson(response)
      }
      setIsSpinnerActive(false)
    })
  }, [selectedFilters])

  return (
    <div className="recruiter-menu-wrapper">
      <Filters
        setSelectedTags={setSelectedFilters}
        selectedFilters={selectedFilters}
      />
      <div className="recruiter-content-wrapper">
        <div className="recruiter-title">
          <FormTitle title="Job Openings" />
          {
          userState.userGroup.includes('Admin') ? (
            <Button
              classButton="add-job-wrapper"
              onClick={() => navigate('/admin/newJob', {
                state: {
                  applicant: state?.applicant,
                },
              })}
              icon={[addBtn, addBtnHover]}
              alt="New job"
              text="Add new job opening"
            />
          ) : ''
        }
        </div>
        <div className="tags-container">
          <Tags showTags={selectedFilters} isClosable setTags={setSelectedFilters} />
        </div>
        <div className="recruiter-content">
          {
          jobsJson && jobsJson.map((job) => (
            <JobCard
              key={job.id}
              processesTotal={job.processes}
              applicant={state?.applicant}
              setSelectedJob={setSelectedJob}
              job={job}
            />
          ))
        }
          {
        jobsJson && jobsJson.length === 0
          ? (
            <div className="no-job">
              <img src={noJob} alt="no jobs" />
              <h1>Oops! No Jobs Found...</h1>
            </div>
          ) : ''
          }
        </div>
      </div>
    </div>
  )
}

RecruiterMenu.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

RecruiterMenu.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default RecruiterMenu
