import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  SET_USER_LOGGED_OUT,
} from '../../UserContext/UserReducer'
import './Navbar.scss'
import Button from '../button/Button'
import miniWotLogo from '../../../assets/images/wotMini.svg'
import logOut from '../../../assets/images/logOut.svg'
import settings from '../../../assets/images/settings.svg'
import tabsJson from '../../../assets/jsons/tabs.json'

const Navbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogOut = () => {
    dispatch(SET_USER_LOGGED_OUT())
  }

  return (
    <div className="nav-wrapper">
      <div className="nav-tabs-container">
        <Button buttonType="logo" classButton="icon" icon={[miniWotLogo]} />
        {
          tabsJson.map((section) => (
            section.tabs.length > 0 ? (
              section.tabs?.map((tabSec) => (
                <Button
                  classButton="nav-tab"
                  key={tabSec.tabName}
                  iconAlt={tabSec.tabName}
                  text={tabSec.tabName}
                  icon={[tabSec.icon]}
                  buttonType="sidebar"
                />
              ))
            ) : ''
          ))
        }
        <div className="nav-log-out">
          <Button text="Settings" buttonType="sidebar" icon={[settings]} classButton="nav-tab" onClick={() => navigate('/settings/profile')} />
          <Button text="Logout" icon={[logOut]} buttonType="sidebar" classButton="nav-tab" onClick={handleLogOut} />
        </div>
      </div>
    </div>
  )
}

export default Navbar
