/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { returnCandidateContext } from '../Contexts/CandidatesContext'
import { loggedUser } from '../../UserContext/UserReducer'
import KanBanColumn from '../Templates/KanBanColumn/kanBanColumn'

import { updateCandidateProcess } from '../../services/Candidates/candidates.service.ts'

import Button from '../button/Button'
import addBtn from '../../../assets/images/addBtn.svg'
import filter from '../../../assets/images/filter.png'

import './candidatesScreen.scss'
import Filters from '../Filters/Filters'
import Tags from '../Tags/Tags'

const CandidatesScreen = ({ setIsSpinnerActive }) => {
  const {
    candidatesJson,
    getCandidatesList,
  } = returnCandidateContext()
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState([])
  const [candidates, setCandidates] = useState()
  const [showFilters, setShowFilters] = useState()
  const userState = useSelector(loggedUser)

  const groupColumns = (candidateID, columnTitle, model) => {
    const dragedFromColumn = candidatesJson.find(
      (item) => item.name === columnTitle,
    )
    const index = dragedFromColumn.processes.findIndex((item) => item.id === candidateID)
    dragedFromColumn.processes.splice(index, 1)
    setCandidates([...candidates])

    updateCandidateProcess(userState.authToken, model)
  }

  const handleCandidatesMenuFetchs = async () => {
    await getCandidatesList(userState.authToken).then((res) => {
      const [status, response] = res
      if (status === 200) {
        setCandidates(response)
      }
      setIsSpinnerActive(false)
    })
  }

  useEffect(async () => {
    const queries = selectedFilters.reduce((groups, item) => {
      const group = (groups[item.id] || [])
      group.push(item.value)
      // eslint-disable-next-line no-param-reassign
      groups[item.id] = group
      return groups
    }, {})

    let completeQuery = ''
    Object.keys(queries).forEach((key) => {
      completeQuery += `${key}=${queries[key].join(',')}&`
    })

    await getCandidatesList(userState.authToken, completeQuery).then((res) => {
      const [status, response] = res
      if (status === 200) {
        setCandidates(response)
      }
      setIsSpinnerActive(false)
    })
  }, [selectedFilters])

  useEffect(async () => {
    setIsSpinnerActive(true)
    await handleCandidatesMenuFetchs()
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      {
        candidates
          ? (
            <div className="candidates-screen-wrapper">
              <div className="candidates-content-wrapper">
                <div className="candidates-header-wrapper">
                  <Tags showTags={selectedFilters} isClosable setTags={setSelectedFilters} />
                  <div className="buttons-container">
                    <div className="filters-container">
                      <Button
                        classButton={`filters-button ${showFilters ? 'active' : ''}`}
                        text="Filters"
                        icon={[filter]}
                        onClick={() => setShowFilters(!showFilters)}
                      />
                      {
                        showFilters
                          ? (
                            <Filters
                              setSelectedTags={setSelectedFilters}
                              selectedFilters={selectedFilters}
                              closeFilters={() => setShowFilters(false)}
                            />
                          )
                          : ''
                      }
                    </div>
                    <Button
                      classButton="add-job-wrapper"
                      onClick={() => navigate('/recruiter/form')}
                      icon={[addBtn]}
                      iconAlt="New candidate"
                      text="Add new candidate"
                    />
                  </div>
                </div>
                <div className="candidates-body-wrapper">
                  {
                  candidates && candidates.map((column) => (
                    <KanBanColumn
                      columnID={column.id}
                      key={`kanban-column-${column.id}`}
                      applicants={column.processes || []}
                      columnTitle={column.name}
                      groupColumns={groupColumns}
                    />
                  ))
                  }
                </div>
              </div>
            </div>
          )
          : ''
      }
    </DndProvider>
  )
}

CandidatesScreen.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

CandidatesScreen.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default CandidatesScreen
