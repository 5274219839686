/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../button/Button'
import FormTitle from '../../Templates/FormsTitle/FormTitle'
import SearchInput from '../../Templates/SearchInput/SearchInput'
import ProfileCard from '../../Templates/ProfileCards/ProfileCard'
import userListJson from '../../../../assets/jsons/users.json'
import roles from '../../../../assets/jsons/roles.json'
import './manageProfiles.scss'

const ManageProfiles = () => {
  const navigate = useNavigate()
  const [filteredUsers, setFilteredUsers] = useState([])
  const [initialList, setInitialList] = useState([])

  useEffect(() => {
    setFilteredUsers([...userListJson])
    setInitialList([...userListJson])
  }, [])

  return (
    <div className="profile-wrapper form-wrapper">
      <FormTitle divClassName="settings-title" title="Manage profiles" />
      <div className="profiles-header">
        <SearchInput
          attributeToFilter={['name', 'lastname']}
          inputClass="full-container"
          initialList={initialList}
          listToFilter={filteredUsers}
          setFilter={setFilteredUsers}
        />
      </div>
      <div className="profiles-container">
        {
          filteredUsers && filteredUsers.map((value, indx) => (
            <ProfileCard
              // eslint-disable-next-line react/no-array-index-key
              key={`user-${indx}}`}
              roleID={value.idRole}
              name={value.name}
              lastname={value.lastname}
              rolesList={roles}
            />
          ))
        }
      </div>
      <div className="form-buttons">
        <Button
          text="Cancel"
          classButton="cancel-button"
          onClick={() => navigate('/home')}
        />
        <Button
          text="Use"
          classButton="accept-button"
          onClick={() => {}}
        />
      </div>
    </div>
  )
}

export default ManageProfiles
