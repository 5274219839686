/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import FormTitle from '../FormsTitle/FormTitle'
import Input from '../FormsElement/Input'
import Textbox from '../FormsElement/Textbox'
import Button from '../../button/Button'
import closeIcon from '../../../../assets/images/close.svg'
import Selector from '../Selector/Selector'
import addIcon from '../../../../assets/images/addBtn.svg'
import BlurScreen from '../BlurScreen/BlurScreen'
import AddNewField from '../AddNewField/AddNewField'
import StarsRating from '../FormsElement/Rating'
import TagsInput from '../../Tags/TagsInput'
import Feedback from '../../Feedback/Feedback'

import remove from '../../../../assets/images/createField/remove.png'
import profileIcon from '../../../../assets/images/settingsProfile.svg'
import editIcon from '../../../../assets/images/edit.svg'
import trashIcon from '../../../../assets/images/trash.png'
import './renderform.scss'
import { returnCandidateContext } from '../../Contexts/CandidatesContext'

const RenderForm = ({
  sections,
  enabledSection,
  closeFunction,
  showSectionAsText,
  handleRemoveItem,
  isNewJobOpening,
  candidateInfo,
  isEditableForm,
  showTags,
  showFeedback,
  getFormData,
  getFormDataByIndex,
  showExistingCandidates,
  isClearableSection,
  isEditableSection,
  onFeedbackEnter,
  selectedQuestions,
  setSelectedQuestions,
}) => {
  const navigate = useNavigate()
  const { countriesJson } = returnCandidateContext()
  const [renderInitialForm, setRenderInitialForm] = useState(-1)
  const [formData, setFormData] = useState([])
  const [sectionSelected, setSectionSelected] = useState()
  const [showBlurScreen, setShowBlurScreen] = useState(false)

  const [sectionToText, setSectionToText] = useState(showSectionAsText)
  // eslint-disable-next-line no-unused-vars
  const [sectionToEdit, setSectionToEdit] = useState(enabledSection)

  useEffect(() => {
    if (sections) {
      const createdFormData = []

      sections.map((item, index) => {
        let createdTemplate = {
          title: item.title || '',
        }
        let createdSectionData = createdTemplate

        item?.questions.map((question) => {
          if (question.type === 'date'
          && question.placeholder
          && question.placeholder !== ''
          && question.placeholder === 'isToday') {
            // eslint-disable-next-line no-param-reassign
            question.placeholder = new Date().toLocaleDateString('en-US')
          }
          if ((question.id === 'limitDate' || question.id === 'openDate') && question.answer) {
            // eslint-disable-next-line no-param-reassign
            question.answer = new Date(question.answer).toISOString().substring(0, 10)
          }
          try {
            if (item.isExistingTemplate) {
              createdTemplate = {
                [`${item.id}-${question.questionID}`]: formData[index][0][question.questionID],
              }
            } else {
              createdTemplate = {
                [question.id]: formData[index][0][question.id],
              }
            }
          } catch (e) {
            if (question.label.toLowerCase() === 'country'
            && question.type === 'selector'
            && question.options.length === 0
            && Array.isArray(question.answer)) {
              // eslint-disable-next-line no-param-reassign
              question.options = [countriesJson.find(
                (country) => country.id === parseInt(question.answer, 10),
              )]
            }
            if (question.answer && Array.isArray(question.answer)) {
              const result = question.answer.map((x) => {
                const tryNumber = parseInt(x, 10)
                if (Number.isNaN(tryNumber)) {
                  return x
                }
                return tryNumber
              })
              // eslint-disable-next-line no-param-reassign
              question.answer = result
            }
            if (item.isExistingTemplate) {
              createdTemplate = {
                [`${item.id}-${question.questionID}`]: question.answer || '',
              }
            } else {
              createdTemplate = {
                [question.id]: question.answer || '',
              }
            }
          }

          createdSectionData = { ...createdSectionData, ...createdTemplate }
          return createdSectionData
        })

        if (!selectedQuestions[item.id]) {
          const questionsIDs = Object.keys(createdSectionData)
          // eslint-disable-next-line no-param-reassign
          selectedQuestions[item.id] = questionsIDs
        }
        createdFormData.push([createdSectionData])
        return createdFormData
      })
      getFormData(createdFormData) // Setting initial object
      setSelectedQuestions(selectedQuestions)
      setFormData(createdFormData)
    }
  }, [sections])

  const handleBlurScreen = (item) => {
    setSectionSelected(item)
    setShowBlurScreen(!showBlurScreen)
  }

  const clearSection = (index) => {
    Object.keys(formData[index][0]).forEach((k) => {
      if (k !== 'title') {
        formData[index][0][k] = ''
      }
    })

    const removeFromEditable = isEditableSection.indexOf(index)
    if (removeFromEditable > -1) { // only splice array when item is found
      isEditableSection.splice(removeFromEditable, 1)
    }

    const removeFromIsText = sectionToText.indexOf(index)
    if (removeFromIsText > -1) { // only splice array when item is found
      sectionToText.splice(removeFromIsText, 1)
      setSectionToText([...sectionToText])
    }

    setRenderInitialForm(renderInitialForm + 1)
  }

  const editableSection = (index) => {
    const removeFromIsText = sectionToText.indexOf(index)
    if (removeFromIsText > -1) { // only splice array when item is found
      sectionToText.splice(removeFromIsText, 1)
      setSectionToText([...sectionToText])
    } else {
      sectionToText.push(index)
    }
    setSectionToText([...sectionToText])
  }

  return (
    <>
      <section className={`${isNewJobOpening ? 'full-render-form' : 'full-form-wrapper'}`}>
        {
          candidateInfo?.applicant && sections && (
          <div className="form-cantidate">
            <div className="candidate-icon" />
            <div className="candidate-info">
              {
            candidateInfo.applicant.section
              ? (
                <h1>
                  {candidateInfo.applicant.section.questions[0].answer[0]}
                  {' '}
                  {candidateInfo.applicant.section.questions[1].answer[0]}
                </h1>
              ) : ''
              }
              {
                candidateInfo && sections.length > 1
                  ? (
                    <h1>
                      {sections[1].questions[0].answer[0]}
                      {' '}
                      {sections[1].questions[1].answer[0]}
                    </h1>
                  ) : ''
              }
              <h1>{candidateInfo.jobOpening?.name || 'No Job Opening'}</h1>
            </div>
          </div>
          )
        }
        <div className={`${isNewJobOpening ? 'form-render-form' : 'form-wrapper'}`}>
          { closeFunction ? <Button classButton="close-form" icon={[closeIcon]} onClick={() => closeFunction()} /> : '' }

          {sections.length > 0 && formData.length > 0
            ? sections.map((item, sectionIndex) => (
              <div className={`${isNewJobOpening ? 'form-container' : 'form-header-wrapper'} ${item.isExistingTemplate ? 'existing-template' : ''} ${item.showInRow ? 'new-section' : ''} ${item.isNewSection ? 'new-section' : ''}`} key={item.id}>
                {
            item.isNewSection
              ? (
                <label htmlFor="new-section">
                  <Button
                    onClick={() => handleRemoveItem(sectionIndex, sectionIndex, true)}
                    icon={[remove]}
                    iconAlt="remove"
                  />
                  <Input
                    initialItemList={formData[sectionIndex] || [{ title: item.title }]}
                    inputName="title"
                    className="form-title-wrapper full-container"
                    placeholder="Name of section"
                    isEditing
                    setChangesByIndex={getFormDataByIndex}
                    changesByIndex={sectionIndex}
                  />
                </label>
              )
              : (
                <>
                  <div className="form-title-container">
                    {
                    item.isExistingTemplate
                      ? (
                        <Button
                          onClick={() => handleRemoveItem(
                            sectionIndex,
                            sectionIndex,
                            true,
                            item.id,
                          )}
                          icon={[remove]}
                          iconAlt="remove"
                        />
                      )
                      : ''
                    }
                    <FormTitle
                      title={item.title}
                      onEdit={isEditableForm
                        ? !sectionToText.includes(sectionIndex)
                          ? () => {
                            sectionToText.push(sectionIndex)
                            setSectionToText([...sectionToText])
                          } : () => {
                            const selectedSection = sectionToText.indexOf(sectionIndex)
                            sectionToText.splice(selectedSection, 1)
                            setSectionToText([...sectionToText])
                          }
                        : undefined}
                    />
                  </div>
                  <div className="form-body-buttons">
                    {
                      showExistingCandidates.includes(sectionIndex)
                        ? (
                          <Button
                            classButton="existing-candidate"
                            icon={[profileIcon]}
                            text="Use existing candidate"
                            onClick={() => navigate('/allcandidates')}
                          />
                        )
                        : ''
                    }
                    <div className="right-buttons">
                      {
                        isEditableSection.includes(sectionIndex)
                          ? (
                            <Button
                              classButton="edit-icon"
                              icon={[editIcon]}
                              onClick={() => editableSection(sectionIndex)}
                            />
                          )
                          : ''
                      }
                      {
                        isClearableSection.includes(sectionIndex)
                          ? (
                            <Button
                              classButton="edit-icon"
                              icon={[trashIcon]}
                              onClick={() => clearSection(sectionIndex)}
                            />
                          ) : ''
                      }
                    </div>
                  </div>
                </>
              )
            }

                <div className="form-body-row">
                  {item.questions?.map((question, questionIndex) => (
                    question.type === 'long'
                      ? (
                        <Input
                          openPopUp={question.isNewQuestion}
                          initialItemList={formData[sectionIndex]}
                          setChangesByIndex={getFormDataByIndex}
                          changesByIndex={sectionIndex}
                          label={question.label}
                          inputName={item.isExistingTemplate ? `${item.id}-${question.questionID}` : question.id}
                          className="full-container"
                          isEditing={!sectionToText.includes(sectionIndex)}
                          isNeeded={question.isMandatory === 1}
                          disabled={!sectionToEdit.includes(sectionIndex)}
                          key={question.id}
                          isSelectable={item.isExistingTemplate}
                          parentId={item.id}
                          validateChecked={selectedQuestions}
                          showRemove={item.isNewSection || item.isExistingTemplate}
                          handleRemoveItem={() => handleRemoveItem(questionIndex, sectionIndex)}
                          renderInitialItemList={renderInitialForm}
                        />
                      )
                      : question.type === 'short' || question.type === 'number' || question.type === 'url' || question.type === 'phone'
                        ? (
                          <Input
                            type={question.type === 'short' ? 'text' : question.type}
                            openPopUp={question.isNewQuestion}
                            initialItemList={formData[sectionIndex]}
                            setChangesByIndex={getFormDataByIndex}
                            changesByIndex={sectionIndex}
                            label={question.label}
                            inputName={item.isExistingTemplate
                              ? `${item.id}-${question.questionID}` : question.id}
                            isEditing={!sectionToText.includes(sectionIndex)}
                            isNeeded={question.isMandatory === 1}
                            disabled={!sectionToEdit.includes(sectionIndex)}
                            key={question.id}
                            isSelectable={item.isExistingTemplate}
                            parentId={item.id}
                            validateChecked={selectedQuestions}
                            showRemove={item.isNewSection || item.isExistingTemplate}
                            handleRemoveItem={() => handleRemoveItem(questionIndex, sectionIndex)}
                            renderInitialItemList={renderInitialForm}
                          />
                        )
                        : question.type === 'textbox'
                          ? (
                            <Textbox
                              openPopUp={question.isNewQuestion}
                              initialItemList={formData[sectionIndex]}
                              setChangesByIndex={getFormDataByIndex}
                              changesByIndex={sectionIndex}
                              label={question.label}
                              rows="3"
                              isEditing={!sectionToText.includes(sectionIndex)}
                              textboxName={item.isExistingTemplate
                                ? `${item.id}-${question.questionID}` : question.id}
                              isNeeded={question.isMandatory === 1}
                              disabled={!sectionToEdit.includes(sectionIndex)}
                              key={question.id}
                              isSelectable={item.isExistingTemplate}
                              parentId={item.id}
                              validateChecked={selectedQuestions}
                              showRemove={item.isNewSection || item.isExistingTemplate}
                              handleRemoveItem={() => handleRemoveItem(questionIndex, sectionIndex)}
                            />
                          )
                          : question.type === 'date'
                            ? (
                              <Input
                                openPopUp={question.isNewQuestion}
                                initialItemList={formData[sectionIndex]}
                                setChangesByIndex={getFormDataByIndex}
                                changesByIndex={sectionIndex}
                                label={question.label}
                                inputName={item.isExistingTemplate
                                  ? `${item.id}-${question.questionID}` : question.id}
                                isEditing={!sectionToText.includes(sectionIndex)}
                                isNeeded={question.isMandatory === 1}
                                disabled={!sectionToEdit.includes(sectionIndex)}
                                type="date"
                                minValue={question.placeholder}
                                key={question.id}
                                isSelectable={item.isExistingTemplate}
                                parentId={item.id}
                                validateChecked={selectedQuestions}
                                showRemove={item.isNewSection || item.isExistingTemplate}
                                handleRemoveItem={
                                  () => handleRemoveItem(questionIndex, sectionIndex)
                                }
                                renderInitialItemList={renderInitialForm}
                              />
                            )
                            : question.type === 'radio' || question.type === 'checkbox'
                              ? (
                                <Input
                                  openPopUp={question.isNewQuestion}
                                  initialItemList={question.options}
                                  setChangesByIndex={getFormDataByIndex}
                                  changesByIndex={sectionIndex}
                                  checkedItems={
                                    formData[sectionIndex]
                                    && formData[sectionIndex][0]
                                    && Array.isArray(formData[sectionIndex][0][question.id])
                                      ? formData[sectionIndex][0][question.id] : []
                                  }
                                  label={question.label}
                                  inputName={item.isExistingTemplate
                                    ? `${item.id}-${question.questionID}` : question.id}
                                  isEditing={!sectionToText.includes(sectionIndex)}
                                  isNeeded={question.isMandatory === 1}
                                  disabled={!sectionToEdit.includes(sectionIndex)}
                                  className="full-container"
                                  type={question.type}
                                  key={question.id}
                                  isSelectable={item.isExistingTemplate}
                                  parentId={item.id}
                                  validateChecked={selectedQuestions}
                                  showRemove={item.isNewSection || item.isExistingTemplate}
                                  handleRemoveItem={
                                    () => handleRemoveItem(questionIndex, sectionIndex)
                                  }
                                  renderInitialItemList={renderInitialForm}
                                />
                              )
                              : question.type === 'selector'
                                ? (
                                  <Selector
                                    initialItemList={formData[sectionIndex]}
                                    setChangesByIndex={getFormDataByIndex}
                                    changesByIndex={sectionIndex}
                                    openPopUp={question.isNewQuestion}
                                    label={question.label}
                                    selectorName={item.isExistingTemplate
                                      ? `${item.id}-${question.questionID}` : question.id}
                                    defaultIndex={
                                      formData[sectionIndex] && formData[sectionIndex][0]
                                        ? formData[sectionIndex][0][question.id] : 0
                                    }
                                    items={question.options}
                                    isNeeded={question.isMandatory === 1}
                                    key={question.id}
                                    isSelectable={item.isExistingTemplate}
                                    parentId={item.id}
                                    validateChecked={selectedQuestions}
                                    showRemove={item.isNewSection || item.isExistingTemplate}
                                    isEditing={!sectionToText.includes(sectionIndex)}
                                    handleRemoveItem={
                                      () => handleRemoveItem(questionIndex, sectionIndex)
                                    }
                                    renderInitialItemList={renderInitialForm}
                                  />
                                ) : question.type === 'qualification'
                                  ? (
                                    <StarsRating
                                      initialItemList={formData[sectionIndex]}
                                      setChangesByIndex={getFormDataByIndex}
                                      changesByIndex={sectionIndex}
                                      openPopUp={question.isNewQuestion}
                                      label={question.label}
                                      ratingName={item.isExistingTemplate
                                        ? `${item.id}-${question.questionID}` : question.id}
                                      isNeeded={question.isMandatory === 1}
                                      key={question.id}
                                      disabled={!sectionToEdit.includes(sectionIndex)}
                                      parentId={item.id}
                                      showRemove={item.isNewSection || item.isExistingTemplate}
                                      isEditing={!sectionToText.includes(sectionIndex)}
                                      isSelectable={item.isExistingTemplate}
                                      handleRemoveItem={
                                        () => handleRemoveItem(questionIndex, sectionIndex)
                                      }
                                      validateChecked={selectedQuestions}
                                    />
                                  ) : question.type === 'schedule' ? (
                                    <Input
                                      type="time"
                                      openPopUp={question.isNewQuestion}
                                      initialItemList={formData[sectionIndex]}
                                      setChangesByIndex={getFormDataByIndex}
                                      changesByIndex={sectionIndex}
                                      label={question.label}
                                      inputName={item.isExistingTemplate
                                        ? `${item.id}-${question.questionID}` : question.id}
                                      isEditing={!sectionToText.includes(sectionIndex)}
                                      isNeeded={question.isMandatory === 1}
                                      disabled={!sectionToEdit.includes(sectionIndex)}
                                      key={question.id}
                                      isSelectable={item.isExistingTemplate}
                                      parentId={item.id}
                                      validateChecked={selectedQuestions}
                                      showRemove={item.isNewSection || item.isExistingTemplate}
                                      handleRemoveItem={
                                        () => handleRemoveItem(questionIndex, sectionIndex)
                                      }
                                      renderInitialItemList={renderInitialForm}
                                    />
                                  ) : ''
                  ))}
                  {
                item.isNewSection
                  ? (
                    <Button
                      onClick={() => handleBlurScreen(item)}
                      text="Add new field"
                      classButton="new-field-button"
                      icon={[addIcon]}
                    />
                  )
                  : ''
              }
                </div>
              </div>
            ))
            : ''}

          {
            showTags
              ? (
                <>
                  <FormTitle title="Tags" />
                  <TagsInput />
                </>
              )
              : ''
          }
          {
            showFeedback ? (
              <>
                <FormTitle title="Feedback" />
                <Feedback
                  onEnter={onFeedbackEnter}
                  pastFeedback={candidateInfo && candidateInfo.feedback !== null
                    ? candidateInfo.feedback : []}
                  applicantID={candidateInfo ? (
                    candidateInfo.applicant
                      ? candidateInfo.applicant.id
                      : candidateInfo.id) : -1}
                  processID={candidateInfo?.applicant ? candidateInfo.id : -1}
                />
              </>
            ) : ''
          }
        </div>
      </section>
      {
        showBlurScreen
          ? (
            <BlurScreen>
              <AddNewField onClose={handleBlurScreen} selectedSection={sectionSelected} />
            </BlurScreen>
          ) : ''
      }
    </>
  )
}

RenderForm.propTypes = {
  candidateInfo: PropTypes.shape({
    id: PropTypes.number,
    applicant: PropTypes.shape({
      id: PropTypes.number,
      section: PropTypes.shape({
        id: PropTypes.number,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            answer: PropTypes.arrayOf(
              PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
              ]),
            ),
          }),
        ),
      }),
    }),
    jobOpening: PropTypes.shape({
      name: PropTypes.string,
    }),
    feedback: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }),
  isNewJobOpening: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          answer: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
          ),
          label: PropTypes.string,
          name: PropTypes.string,
          isMandatory: PropTypes.number,
        }),
      ),
    }),
  ),
  getFormData: PropTypes.func,
  enabledSection: PropTypes.arrayOf(
    PropTypes.number,
  ),
  closeFunction: PropTypes.func,
  showSectionAsText: PropTypes.arrayOf(
    PropTypes.number,
  ),
  handleRemoveItem: PropTypes.func,
  isEditableForm: PropTypes.bool,
  showTags: PropTypes.bool,
  showFeedback: PropTypes.bool,
  getFormDataByIndex: PropTypes.func,
  showExistingCandidates: PropTypes.arrayOf(
    PropTypes.number,
  ),
  isEditableSection: PropTypes.arrayOf(
    PropTypes.number,
  ),
  isClearableSection: PropTypes.arrayOf(
    PropTypes.number,
  ),
  onFeedbackEnter: PropTypes.func,
  selectedQuestions: PropTypes.shape({}),
  setSelectedQuestions: PropTypes.func,
}

RenderForm.defaultProps = {
  candidateInfo: undefined,
  isNewJobOpening: true,
  sections: [],
  getFormData: () => {},
  enabledSection: [],
  closeFunction: undefined,
  showSectionAsText: [],
  handleRemoveItem: () => {},
  isEditableForm: false,
  showTags: false,
  showFeedback: false,
  getFormDataByIndex: undefined,
  showExistingCandidates: [],
  isEditableSection: [],
  isClearableSection: [],
  onFeedbackEnter: undefined,
  selectedQuestions: {},
  setSelectedQuestions: () => {},
}

export default RenderForm
