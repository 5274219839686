import React from 'react'
import PropTypes from 'prop-types'
import './TagsInput.scss'

const Tags = ({ showTags, isClosable, setTags }) => {
  // Funcion onClick al querer eliminar un elemento
  const removeTag = async (result) => {
    const list = [...showTags]
    const index = showTags.findIndex((item) => item.tag === result.tag)

    if (index !== -1) {
      list.splice(index, 1)
    }

    setTags(list)
  }

  return (
    <>
      {showTags.map((result) => (
        <li className="tag" key={`${result.id}-${result.tag}`}>
          {result.tag}

          { isClosable ? <button type="button" onClick={() => removeTag(result)}>✖</button> : ''}
        </li>
      ))}
    </>
  )
}

Tags.propTypes = {
  showTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    tag: PropTypes.string,
  })),
  isClosable: PropTypes.bool,
  setTags: PropTypes.func,
}

Tags.defaultProps = {
  showTags: [],
  isClosable: false,
  setTags: () => {},
}

export default Tags
