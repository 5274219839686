/* eslint-disable react/prop-types */
import React from 'react'
import './blurscreen.scss'

const BlurScreen = ({ children, className }) => (
  <div className={`blur-screen ${className}`}>
    <div className="container">
      { children }
    </div>
  </div>
)

export default BlurScreen
