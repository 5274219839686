import React, { useState } from 'react'
import PropTypes from 'prop-types'
import close from '../../../assets/images/close.svg'
import mostUsedTags from '../../../assets/jsons/mostUsedTags.json'
import Button from '../button/Button'

const TagsSuggestions = ({ onTagClick }) => {
  const [showSuggestions, setShowSuggestions] = useState(true)

  return (
    showSuggestions
      ? (
        <div className="suggestions-container">
          <div className="suggestions-header">
            <p>Suggestions related to job</p>
            <Button classButton="close-icon" onClick={() => setShowSuggestions(false)} icon={[close]} />

          </div>
          <div className="suggestions">
            {
                mostUsedTags.map((item) => (
                  <Button classButton="tag" onClick={() => onTagClick(item)} text={item.value} key={item.id} />
                ))
            }
          </div>
        </div>
      )
      : ''
  )
}

TagsSuggestions.propTypes = {
  onTagClick: PropTypes.func,
}

TagsSuggestions.defaultProps = {
  onTagClick: () => {},
}

export default TagsSuggestions
