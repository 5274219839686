import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SET_BLUR_SCREEN } from '../../../UserContext/UserReducer'

import success from '../../../../assets/images/success.svg'
import failure from '../../../../assets/images/failed.svg'
import Button from '../../button/Button'

const FormDialog = ({
  isOkay, onClose, errorMsg, okayMsg,
}) => {
  const dispatch = useDispatch()

  const handleBlurScreen = () => {
    dispatch(SET_BLUR_SCREEN({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
      showBlurScreen: false,
    }))
  }

  return (
    <>
      <img src={isOkay ? success : failure} alt="status" className="status-icon" />
      <h1>{isOkay ? okayMsg : errorMsg}</h1>
      <Button
        text={isOkay ? 'Ok' : 'Try again'}
        classButton="blur-btn"
        onClick={onClose ? () => onClose() : handleBlurScreen}
      />
    </>
  )
}

FormDialog.propTypes = {
  isOkay: PropTypes.bool,
  onClose: PropTypes.func,
  okayMsg: PropTypes.string,
  errorMsg: PropTypes.string,
}
FormDialog.defaultProps = {
  isOkay: false,
  onClose: undefined,
  okayMsg: 'The interview was saved successfully!',
  errorMsg: 'The interview was not saved!',
}
export default FormDialog
