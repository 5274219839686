import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loggedUser } from '../../UserContext/UserReducer'
import Button from '../button/Button'
import RenderForm from '../Templates/RenderForm/RenderForm'
import ExistingForms from './ExistingForms'
import BlurScreen from '../Templates/BlurScreen/BlurScreen'
import FormDialog from '../Templates/Saveform/FormDialog'

import { returnCreateJobContext } from '../Contexts/CreateJobContext'
import { returnCandidateContext } from '../Contexts/CandidatesContext'
import { editJobOpening } from '../../services/JobOpenings/jobOpenings.service.ts'

const EditJobOpening = ({ setIsSpinnerActive }) => {
  const navigate = useNavigate()
  const userState = useSelector(loggedUser)
  const {
    fullSections,
    getInitialData,
  } = returnCreateJobContext()

  const {
    selectedJob,
  } = returnCandidateContext()

  const [selectedQuestions, setSelectedSection] = useState({})
  const [finalFormData, getFormData] = useState(undefined)
  const [showExistingForms, setShowExistingForms] = useState(false)

  const [showBlurScreen, setShowBlurScreen] = useState()
  const [isOkay, setIsOkay] = useState()

  useEffect(async () => {
    if (!selectedJob) navigate('/')
    if (fullSections.length === 0) {
      setIsSpinnerActive(true)
      await getInitialData(selectedJob)
      setIsSpinnerActive(false)
    }
  }, [])

  const getFormDataByIndex = (index, changes, inputName) => {
    if (inputName === 'title') {
      fullSections[index][inputName] = changes[0][inputName]
    }
    finalFormData[index] = [{
      ...finalFormData[index][0],
      ...changes[0],
    }]
    getFormData(finalFormData)
  }

  const saveSelection = async () => {
    const model = cloneDeep(finalFormData)
    // DELETE UNNECESARY ID
    delete model[0]['general-1']
    delete model[0][0].title

    // MAKE SURE DATA IS PARSED TO NUMBER
    model[0][0].rateMin = parseFloat(model[0][0].rateMin)
    model[0][0].rateMax = parseFloat(model[0][0].rateMax)
    model[0][0].clientID = parseFloat(model[0][0].clientID)
    model[0][0].countryID = parseFloat(model[0][0].countryID)
    model[0][0].companyID = parseFloat(model[0][0].companyID)
    model[0][0].typeID = parseFloat(model[0][0].typeID)

    if (model[0][0].openDate === '') delete model[0][0].openDate
    if (model[0][0].limitDate === '') delete model[0][0].limitDate

    // JOB OPENING TO POST
    const jobToPost = {
      id: selectedJob.id,
      ...model[0][0],
    }

    const [statusCode] = await editJobOpening(userState.authToken, jobToPost)
    setIsOkay(statusCode === 200)
    setShowBlurScreen(true)
  }

  const onClose = () => {
    if (isOkay) navigate('/home')
    else setShowBlurScreen(false)
  }

  return (
    <>
      {showExistingForms
        ? <ExistingForms closeExistingForms={() => setShowExistingForms(false)} />
        : ''}

      <div className="form-wrapper new-job-opening" style={showExistingForms ? { display: 'none' } : { display: 'flex' }}>
        <div className="form-header-wrapper">
          <RenderForm
            sections={fullSections}
            enabledSection={[0]}
            getFormData={getFormData}
            getFormDataByIndex={getFormDataByIndex}
            setSelectedQuestions={setSelectedSection}
            selectedQuestions={selectedQuestions}
          />
          <div className="form-buttons">
            <Button
              text="Cancel"
              classButton="cancel-button"
              onClick={() => navigate('/home')}
            />
            <Button
              text="Use"
              classButton="accept-button"
              onClick={saveSelection}
            />
          </div>
        </div>
      </div>

      {
        showBlurScreen
          ? (
            <BlurScreen>
              <FormDialog isOkay={isOkay} onClose={onClose} />
            </BlurScreen>
          )
          : ''
      }
    </>
  )
}

EditJobOpening.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

EditJobOpening.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default EditJobOpening
