import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../button/Button'
import icon from '../../../../assets/images/settingsProfileOrange.svg'
import Selector from '../Selector/Selector'
import './profileCard.scss'

const ProfileCard = ({
  roleID, name, lastname, rolesList,
}) => (
  <div className="profile-card-wrapper">
    <Button icon={[icon]} text={`${name} ${lastname}`} />
    <Selector
      extraSelectorClass="profile-selector-item"
      items={rolesList}
      extraClass="profile-selector"
      defaultIndex={roleID}
    />
  </div>
)

ProfileCard.propTypes = {
  roleID: PropTypes.number,
  name: PropTypes.string,
  lastname: PropTypes.string,
  rolesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}
ProfileCard.defaultProps = {
  roleID: 0,
  name: '',
  lastname: '',
  rolesList: undefined,
}

export default ProfileCard
