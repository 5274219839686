/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import PropTypes from 'prop-types'
import './input.scss'
import Checkbox from './Checkbox'
import PopUpEditInput from '../../popup/PopUp'
import remove from '../../../../assets/images/createField/remove.png'
import Button from '../../button/Button'

const StarsRating = ({
  label, ratingName, initialItemList, setChanges,
  disabled, isSelectable,
  isNeeded, validateChecked, parentId, openPopUp,
  showRemove, handleRemoveItem,
  setChangesByIndex, changesByIndex,

}) => {
  const [itemList, setItemList] = useState(initialItemList || [{ value: '' }])
  const [showPopUp, setShowPopUp] = useState(false)

  const handleCheckboxChange = (e) => {
    const { checked } = e.target

    if (isSelectable && parentId) {
      const selectedElements = validateChecked[parentId]
      if (checked) {
        selectedElements.push(ratingName.toString())
      } else {
        const index = selectedElements.indexOf(ratingName.toString())
        if (index !== -1) {
          selectedElements.splice(index, 1)
        }
      }
    }
  }

  const handleItemChange = (rate) => {
    const list = [...itemList]
    list[0][ratingName] = rate

    if (setChanges) {
      setChanges(list)
    } else if (setChangesByIndex !== undefined && changesByIndex !== undefined) {
      setItemList(list)
      setChangesByIndex(changesByIndex, [{
        [ratingName]: rate,
      }], ratingName)
    } else {
      setItemList(list)
    }
  }

  return (
    <div className={`input-full-container ${showPopUp ? 'selected' : ''}`}>
      <div className="label-container" onClick={openPopUp ? () => setShowPopUp(!showPopUp) : () => {}}>
        {
              label
                ? (
                  isSelectable
                    ? (
                      <div className="normal-checkbox">
                        <Checkbox
                          value={label}
                          isNeeded={isNeeded}
                          label={label}
                          handleItemChange={handleCheckboxChange}
                          checked
                        />
                      </div>
                    )
                    : (
                      <label htmlFor={ratingName}>
                        {showRemove
                          && (
                          <Button onClick={() => handleRemoveItem()} icon={[remove]} iconAlt="remove" />
                          ) }
                        {label}
                        { isNeeded
                          ? <span className="neeeded-attr">*  </span> : ''}
                      </label>
                    )
                )
                : ''
              }

        <Rating
          readonly={disabled}
          onClick={handleItemChange}
          ratingValue={itemList[0][ratingName]}
          allowHalfIcon
        />
      </div>
      {
        showPopUp
          ? (
            <PopUpEditInput
              defaultValue={label}
              type="textbox"
              parentId={parentId}
              childId={ratingName}
              closePopup={() => setShowPopUp(false)}
            />
          ) : ''
      }
    </div>
  )
}

StarsRating.propTypes = {
  label: PropTypes.string,
  initialItemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.string,
    }),
  ),
  ratingName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setChanges: PropTypes.func,
  disabled: PropTypes.bool,
  isSelectable: PropTypes.bool,
  validateChecked: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  isNeeded: PropTypes.bool,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  openPopUp: PropTypes.bool,
  showRemove: PropTypes.bool,
  handleRemoveItem: PropTypes.func,
  setChangesByIndex: PropTypes.func,
  changesByIndex: PropTypes.number,
}

StarsRating.defaultProps = {
  label: undefined,
  initialItemList: undefined,
  setChanges: undefined,
  disabled: false,
  isSelectable: false,
  validateChecked: [],
  isNeeded: false,
  parentId: undefined,
  openPopUp: false,
  showRemove: false,
  handleRemoveItem: () => {},
  setChangesByIndex: undefined,
  changesByIndex: undefined,
}

export default StarsRating
