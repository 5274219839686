import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import './theme.scss'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './UserContext/store'
import { FetchCandidatesInfo } from './components/Contexts/CandidatesContext'
import App from './App'

const root = (
  <Provider store={store}>
    <FetchCandidatesInfo>
      <PersistGate persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </FetchCandidatesInfo>
  </Provider>
)

ReactDOM.render(root, document.getElementById('root'))
