import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loggedUser } from '../../../UserContext/UserReducer'

import { assignCandidateToJO } from '../../../services/Candidates/candidates.service.ts'
import { createFeedback } from '../../../services/Feedback/feedback.service.ts'
import { returnCandidateContext } from '../../Contexts/CandidatesContext'

import FormTitle from '../../Templates/FormsTitle/FormTitle'
import Button from '../../button/Button'
import Input from '../../Templates/FormsElement/Input'
import RenderForm from '../../Templates/RenderForm/RenderForm'

import './candidateForm.scss'
import FormDialog from '../../Templates/Saveform/FormDialog'
import BlurScreen from '../../Templates/BlurScreen/BlurScreen'

const CandidateForm = ({ setIsSpinnerActive }) => {
  const {
    createNewCandidate,
    selectedJob,
    setSelectedJob,
    candidateTemplateModel,
  } = returnCandidateContext()
  const [candidateSection, setCandidateSection] = useState(cloneDeep(candidateTemplateModel))

  const location = useLocation()
  const { state } = location
  const navigate = useNavigate()
  const userState = useSelector(loggedUser)
  const [finalFormData, getFormData] = useState(undefined)
  const [fillSections, setFillSections] = useState()
  const feedbackToPost = []

  const [showBlurScreen, setShowBlurScreen] = useState()
  const [isOkay, setIsOkay] = useState()

  const [errorMsg, setErrorMsg] = useState('Oops! The interview was not saved')

  const [formData, setFormData] = useState([{
    client: selectedJob?.companyName,
    minRate: `$${selectedJob?.rateMin}`,
    rol: selectedJob?.name,
    maxRate: `$${selectedJob?.rateMax}`,
    jobDescription: selectedJob?.jobDescriptorPath,
    country: selectedJob?.countryName,
    englishReq: selectedJob?.englishRequired,
    available: 'June 25, 2023',
    creator: selectedJob?.createdBy,
    region: selectedJob?.expectedRegionProfile,
    description: selectedJob?.description,
  }])

  const getFormDataByIndex = (index, changes) => {
    finalFormData[index] = [{
      ...finalFormData[index][0],
      ...changes[0],
    }]
  }

  const onFeedbackEnter = (feedback) => {
    feedbackToPost.push(feedback)
  }

  useEffect(() => {
    const index = candidateSection[0].questions.findIndex((item) => item.label === 'Recruiter')
    candidateSection[0].questions.splice(index, 1)// Remove recruiter
    setCandidateSection(candidateSection)

    if (!selectedJob && state?.newIntervieew) {
      navigate('/home')
      return
    }
    if ((!selectedJob && !state?.newIntervieew) || (selectedJob && !state)) {
      setSelectedJob(undefined)
    } else if (selectedJob && state && state.applicant) {
      selectedJob.form.sections[0] = state.applicant.section
    }
    setFillSections(true)

    if (state?.newIntervieew) {
      const recruiterIndex = selectedJob.form.sections[0].questions.findIndex((item) => item.label === 'Recruiter')
      selectedJob.form.sections[0].questions.splice(recruiterIndex, 1)// Remove recruiter

      for (let i = 1; i < selectedJob?.form?.sections.length; i += 1) {
        // eslint-disable-next-line no-param-reassign
        selectedJob.form.sections[i].showInRow = true
      }
    }
  }, [])

  const assignateToJO = () => {
    const questions = finalFormData.flat()
    const model = []

    const filledForm = selectedJob?.form.sections.every((section, index) => {
      const questionFilled = section.questions.every((question) => {
        if (question.isMandatory && questions[index][question.id] === '') {
          setErrorMsg('Oops! Not all required data was filled')
          setIsOkay(false)
          setShowBlurScreen(true)
          return false
        }
        return true
      })
      return questionFilled
    })
    if (selectedJob?.form.sections && !filledForm) return undefined

    for (let i = 1; i < questions.length; i += 1) {
      Object.keys(questions[i]).forEach((key) => {
        if (key === 'title') return
        const value = questions[i][key]
        model.push({
          id: parseInt(key, 10),
          answers: Array.isArray(value) ? value : [value],
        })
      })
    }

    return model
  }

  const handleBlurScreen = async () => {
    const candidateInfo = cloneDeep(finalFormData[0][0])
    delete candidateInfo.title

    const filledCandidate = candidateSection[0].questions.every((question) => {
      if (question.isMandatory && candidateInfo[question.id] === '') {
        setErrorMsg('Oops! Not all required data was filled')
        setIsOkay(false)
        setShowBlurScreen(true)
        return false
      }
      return true
    })
    if (!filledCandidate) return

    const applicantDataToPost = []
    Object.keys(candidateInfo).forEach((key) => {
      applicantDataToPost.push({
        id: parseInt(key, 10),
        answers: [candidateInfo[key]],
      })
    })

    const model = assignateToJO()
    if (model === undefined) return

    const finalModel = {
      jobOpeningID: selectedJob?.id,
      applicantID: -1,
      questions: model,
    }

    if (!state?.applicant) {
      setIsSpinnerActive(true)
      const [statusCode, response] = await createNewCandidate({
        questions: applicantDataToPost,
      }, userState.authToken)

      if (statusCode === 200) {
        if (!selectedJob) {
          feedbackToPost.map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.applicantID = response.id
            createFeedback(userState.authToken, item)
            return ''
          })
          navigate('/')
        }
        finalModel.applicantID = response.id
      } else {
        setShowBlurScreen(true)
        setIsOkay(false)
      }
    } else {
      finalModel.applicantID = state.applicant.id
    }

    if (finalModel.applicantID !== -1 && selectedJob) {
      setIsSpinnerActive(true)
      // eslint-disable-next-line no-unused-vars
      const [status, response] = await assignCandidateToJO(userState.authToken, finalModel)
      feedbackToPost.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.applicantID = finalModel.applicantID
        createFeedback(userState.authToken, item)
        return ''
      })
      setShowBlurScreen(true)
      setIsOkay(status === 200)
    }

    setIsSpinnerActive(false)
  }

  const onClose = () => {
    if (isOkay) navigate('/')
    else setShowBlurScreen(false)
  }

  return (
    <>
      <div className="form-wrapper">
        {
        state?.newIntervieew
          ? (
            <div className="form-header-wrapper">
              <FormTitle title="General information of Job opening" />
              <div className="form-body-row">
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Client"
                  inputName="client"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Min Rate"
                  inputName="minRate"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Rol"
                  inputName="rol"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Max Rate"
                  inputName="maxRate"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Job Description"
                  inputName="jobDescription"
                  className="full-container"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Country"
                  inputName="country"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="English required"
                  inputName="englishReq"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Available until"
                  inputName="available"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Creator"
                  inputName="creator"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Expected region"
                  inputName="region"
                  className="full-container"
                />
                <Input
                  initialItemList={formData}
                  setChanges={setFormData}
                  label="Description"
                  inputName="description"
                  className="full-container"
                />
              </div>
            </div>
          )
          : ''
      }

        {
        fillSections
          ? (
            <RenderForm
              isNewJobOpening={false}
              sections={selectedJob?.form.sections || candidateSection}
              showSectionAsText={state?.applicant ? [0] : []}
              enabledSection={Array(
                selectedJob?.form.sections.length,
              )
                .fill(null).map((_, i) => i) || [0]}
              candidateInfo={state?.applicant ? state.applicant : undefined}
              showExistingCandidates={state?.newIntervieew ? [0] : []}
              isClearableSection={[0]}
              getFormData={getFormData}
              getFormDataByIndex={getFormDataByIndex}
              onFeedbackEnter={onFeedbackEnter}
              showFeedback
            />
          )
          : ''
      }

        <div className="form-footer-send-btn-wrapper">
          <Button text="Ok" classButton="new-interview" onClick={handleBlurScreen} />
        </div>
      </div>

      {
      showBlurScreen
        ? (
          <BlurScreen>
            <FormDialog
              isOkay={isOkay}
              onClose={onClose}
              errorMsg={errorMsg}
            />
          </BlurScreen>
        )
        : ''
    }
    </>
  )
}

CandidateForm.propTypes = {
  setIsSpinnerActive: PropTypes.func,
}

CandidateForm.defaultProps = {
  setIsSpinnerActive: () => {},
}

export default CandidateForm
